.accesses__itemCol {
  color: $blueGrayLight;
  display: inline-flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 15px 8px 10px;
  position: relative;
  transition: all 0.5s ease;
  width: 180px;
  align-items: flex-start;
  align-content: flex-start;
  &__name {
    width: 200px;
    @include breakpoint(md) {
      max-width: 220px;
    }
  }
  &__wiki {
    width: 140px;
  }
  &__telegram {
    width: 150px;
  }
  &__gitRepository {
    width: 190px;
  }
  &__site {
    width: 200px;
  }
  &__kritlabGroup {
    width: 150px;
  }
  &__kritlabCompany {
    width: 220px;
  }
  & a {
    text-decoration: none;
    transition: color 0.2s linear;
  }
  &--head {
    align-items: center;
  }
  @include breakpoint(sm) {
    &:first-of-type {
      &::after {
        left: 32px;
      }
    }

    &:last-of-type {
      &::after {
        right: 32px;
      }
    }
  }
  @include breakpoint(md) {
    &:first-of-type {
      &::after {
        left: 22px;
      }
    }
  }
  @include breakpoint(lg) {
    &:first-of-type {
      padding-left: 22px;
      padding-right: 32px;
      max-width: 200px;
    }

    &:last-of-type {
      padding-left: 10px;
      &::after {
        right: 14px;
      }
    }
  }
}
.accesses__editIcon {
  width: 50px;
  min-width: 40px;
  padding-right: 10px;
}
