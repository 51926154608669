.accessDetail__itemCol {
  color: $blueGrayLight;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  padding: 17px 0 0 20px;
  position: relative;
  transition: all 0.5s ease;
  flex-grow: 1;
  @include breakpoint(sm) {
    padding: 26px 0 20px 20px;
  }
  @include breakpoint(lg) {
    padding: 22px 0 22px 20px;
  }

  &::after {
    @include breakpoint(sm) {
      border-top: 1px solid $blueLight;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .dark-theme & {
      border-top: 1px solid $dark;
    }

    .accessDetail__item:first-of-type & {
      display: none;
    }
  }

  &:first-of-type {
    &::after {
      left: 17px;
    }
  }

  &:last-of-type {
    &::after {
      right: 10px;
    }
  }

  &--gray {
    background-color: $light;
    color: $blueGrayLight;

    .dark-theme & {
      background-color: #27272b;
      color: $blueGrayLight;
    }

    .accessDetail__item:first-of-type & {
      border-radius: 8px 8px 0 0;
    }

    .accessDetail__item:last-of-type & {
      border-radius: 0 0 8px 8px;
    }
  }

  &--type {
    border-radius: 15px 0 0 15px;
    font-size: 14px;
    min-width: 90px;
    transition: all 0.5s ease;
    width: 100%;
    flex-grow: 5;
    @include breakpoint(sm) {
      width: 12.2%;
    }
    @include breakpoint(md) {
      width: 16%;
    }
    @include breakpoint(lg) {
      width: 15.7%;
      flex-grow: 1;
    }

    .accessDetail__itemInner {
      word-break: break-word;
    }

    .d-sm-flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include breakpoint(sm) {
        width: auto;
        justify-content: stretch;
      }

      .burger {
        order: 1;
        margin-right: 0;
        @include breakpoint(sm) {
          order: unset;
          margin-right: 17px;
        }
      }
    }
  }

  &--host {
    min-width: 124px;
    width: 100%;
    @include breakpoint(sm) {
      width: 17%;
    }
    @include breakpoint(md) {
      width: 10%;
    }
    @include breakpoint(lg) {
      width: 10%;
    }

    .accessDetail__item:first-of-type & {
      border-radius: 8px 0 0;
    }

    .accessDetail__item:last-of-type & {
      border-radius: 0 0 0 8px;
    }
  }

  &--notice {
    display: none;
    @include breakpoint(md) {
      display: inline-flex;
      width: calc(36.9% - 100px);
    }
    @include breakpoint(lg) {
      width: calc(31.2% - 104px);
    }

    .accessDetail__itemInner {
      flex-wrap: wrap;
      word-break: break-word;
    }

    .accessDetail__item:first-of-type & {
      border-radius: 0 8px 0 0;
    }

    .accessDetail__item:last-of-type & {
      border-radius: 0 0 8px;
    }
  }

  &--login {
    min-width: 99px;
    width: 100%;
    @include breakpoint(sm) {
      width: 13.5%;
    }
    @include breakpoint(md) {
      width: 13.9%;
    }
    @include breakpoint(lg) {
      width: 17.4%;
    }
  }

  &--pass {
    min-width: 116px;
    width: 100%;
    @include breakpoint(sm) {
      width: 16%;
    }
    @include breakpoint(md) {
      min-width: 111px;
      padding-right: 10px;
      width: 12.4%;
    }
    @include breakpoint(lg) {
      width: 13.2%;
    }
  }

  &--actions {
    position: absolute;
    width: auto;
    right: 20px;
    bottom: 30px;
    @include breakpoint(sm) {
      position: relative;
      right: unset;
      bottom: unset;
      width: 5%;
      padding-left: 10px;
      padding-right: 20px;
    }
    @include breakpoint(lg) {
      padding-left: 39px;
    }

    .editIcon {
      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }

      path {
        fill: $grayDark;
      }
    }
  }

  &--head {
    align-items: center;
    color: $defaultColor;
    height: 50px;
    padding-bottom: 0;
    padding-top: 0;
    @include breakpoint(sm) {
      &[data-title-sm]::before {
        content: attr(data-title-sm);
      }
    }
    @include breakpoint(md) {
      height: 52px;

      &[data-title-md]::before {
        content: attr(data-title-md);
      }
    }
    @include breakpoint(lg) {
      padding: 0 0 0 20px;
    }

    .dark-theme & {
      color: $white;
    }

    &[data-title]::before {
      content: attr(data-title);
      white-space: nowrap;
    }
  }

  &--border {
    &::after {
      border-bottom: 1px solid $blue;
      border-top: 0;
      bottom: 0;
      content: "";
      display: block !important;
      left: 0;
      position: absolute;
      right: 0;

      .dark-theme & {
        border-bottom: 1px solid $darkgrayHover;
        border-top: 0;
      }
    }
  }
}
.accessCardInfo {
  display: flex;
  align-items: center;
  h2 {
    font-size: 20px;
    line-height: 26px;
  }
  a {
    height: 24px;
    margin-bottom: 4px;
  }
  svg {
    width: 24px;
    height: 24px;
    color: $blue;
    margin-left: 20px;
    opacity: 0.6;
    transition: opacity 0.2s linear;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
.linksInInfo {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  a {
    padding-right: 10px;
  }
}
.accessCheckbox {
  display: flex;
  label {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }
}
