.login__button {
  background-color: transparent;
  border: 0;
  bottom: -16px;
  cursor: pointer;
  display: inline-flex;
  left: 50%;
  margin-left: -30px;
  padding: 0;
  position: absolute;
  text-decoration: none;
  vertical-align: top;
  @include breakpoint(sm) {
    margin-left: 0;
    position: static;
  }
  @include breakpoint(md) {
    align-items: center;
    border: 1px solid $light-2;
    border-radius: 30px;
    padding-right: 25px;

    .dark-theme & {
      border: 1px solid $blueGray;
    }
  }

  &Icon {
    align-items: center;
    background-color: $blue;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 60px;
    justify-content: center;
    transition: all 0.5s ease;
    width: 60px;
    @include breakpoint(md) {
      align-items: center;
      margin: -1px 17px -1px -1px;
    }

    .dark-theme & {
      background-color: $darkgrayHover;
    }

    .login__button:hover & {
      background-color: $darkgrayHover;
    }

    .dark-theme .login__button:hover {
      background-color: $blue;
    }
  }

  &Text {
    color: $titleColor;
    display: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    @include breakpoint(md) {
      display: block;
    }

    .dark-theme & {
      color: $white;
    }
  }
}

.login__link {
  text-decoration: none;
}
