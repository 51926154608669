.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 10px;
  position: relative;
  @include breakpoint(sm) {
    justify-content: flex-start;
    padding: 0;

    .searchPage & {
      padding: 0 24px;
    }
  }
  @include breakpoint(lg) {
    margin-top: 46px;
  }

  &__wrap {
    background-color: $white;
    border-radius: 10px;
    display: inline-block;
    padding: 4px 9px !important;
    transition: all 0.5s ease;
    vertical-align: top;

    .dark-theme & {
      background-color: $dark-2;
    }
  }

  &__list {
    @include list-unstyled;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
  }

  &__item {
    margin: 0 5px;
    padding: 0;
    position: relative;

    &.active {
      z-index: 2;
    }

    &::before {
      border: 1px solid transparent;
      border-radius: 10px;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.3s ease;
      z-index: 0;
    }

    &--current::before {
      background-color: $white;
      border: 1px solid $blueLight;
      bottom: 0;
      left: -5px;
      right: -5px;
      top: 0;

      .dark-theme & {
        background-color: $dark-2;
        border: 1px solid rgba(142, 173, 255, 0.3);
      }
    }

    &--dots {
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }

    &--arr {
      svg path {
        stroke: $blue;
        transition: all 0.5s ease;
      }

      .dark-theme & {
        svg path {
          stroke: #8eadff;
        }
      }

      .dark-theme &:hover {
        svg path {
          stroke: $white;
        }
      }
    }
  }

  &__link {
    align-items: center;
    background: none;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    color: $blue;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    height: 42px;
    justify-content: center;
    line-height: 24px;
    padding: 0;
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 31px;

    .dark-theme .pagination__item--current & {
      color: $white;
    }

    .dark-theme & {
      color: #8eadff;
    }

    .dark-theme .pagination__item:hover & {
      color: $white;
    }
  }

  &__hover {
    background-color: transparent;
    border-radius: 15px;
    box-shadow: none;
    display: block;
    height: 50px;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease;
    width: 51px;

    .hovered & {
      background-color: $white;
      box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
      opacity: 1;
    }

    .dark-theme .hovered & {
      background-color: $dark-2;
      box-shadow: 5px 5px 10px $darkMedium;
    }
  }
}
