.favorite {
  align-items: center;
  background-color: $white;
  border: 1px solid $light-2;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0;
  text-decoration: none;

  &--added .favorite__icon {
    animation: zoomFavorite 0.5s ease-in-out;
  }

  .dark-theme & {
    background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
  }

  &__text {
    color: $titleColor;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    .dark-theme & {
      color: $white;
    }

    &Item {
      margin-left: 13px;

      &--xs {
        @include breakpoint(sm) {
          display: none;
        }
      }

      &--md {
        display: none;
        @include breakpoint(md) {
          display: block;
        }
        @include breakpoint(lg) {
          display: none;
        }
      }

      &--other {
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
        @include breakpoint(md) {
          display: none;
        }
        @include breakpoint(lg) {
          display: block;
        }
      }
    }

    &--added {
      display: none;

      .favorite--added & {
        display: block;
      }
    }

    &--notadded {
      display: block;

      .favorite--added & {
        display: none;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    height: 19px;
    transition: all 0.5s ease;
    width: 19px;

    svg {
      display: block;
      fill: $blueLight;
      height: 100%;
      transition: all 0.5s ease;
      width: 100%;

      &:hover {
        fill: #f7db15;
      }
    }

    .favorite--added & {
      svg {
        fill: $yellow;

        &:hover {
          fill: #857b30;
        }
      }
    }
  }
}
@keyframes zoomFavorite {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
