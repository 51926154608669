.myKritlabWiki {
  overflow: hidden;
  padding-bottom: 72px;
  @include breakpoint(sm) {
    padding-bottom: 53px;
  }
  @include breakpoint(md) {
    padding-bottom: 79px;
  }
}
