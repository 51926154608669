.tasksUsers {
  text-align: center;

  &__list {
    background: $white;
    border: 1px solid #dbe9f7;
    border-radius: 10px;
    padding: 17px 9px 20px;
    @include breakpoint(sm) {
      padding: 18px 19px 11px;
    }

    .dark-theme & {
      background-color: $dark;
      border: 1px solid $dark-2;
    }

    &Wrap {
      @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        &--hidden {
          display: none;
        }
      }
      @include breakpoint(md) {
        margin: 0 -10px;
      }
      @include breakpoint(lg) {
        margin: 0 -12px;
      }
      @include breakpoint(xl) {
        margin: 0 -5px;
      }

      &--hidden {
        display: none;
      }
    }
  }

  &__item {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    @include breakpoint(sm) {
      padding: 0 5px;
      width: 33.333%;
    }
    @include breakpoint(md) {
      padding: 0 10px;
      width: 25%;
    }
    @include breakpoint(lg) {
      padding: 0 12px;
    }
    @include breakpoint(xl) {
      padding: 0 5px;
      width: 16.666%;
    }

    input {
      flex-shrink: 0;
      height: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      width: 0;

      &:checked ~ div {
        background-color: $darkgrayHover;
        border: 1px solid $darkgrayHover;

        &.tasksUsers__itemWrap--all {
          background-color: $blue;
          border: 1px solid $blue;
        }

        .tasksUsers__itemName {
          color: $white;
        }

        .tasksUsers__itemCount {
          color: $white;
        }
      }
    }

    &Wrap {
      background-color: $white;
      border: 1px solid $blueLight;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      height: 60px;
      justify-content: space-between;
      padding: 12px 18px 12px 14px;
      text-decoration: none;
      transition: all 0.5s ease;
      width: 100%;
      @include breakpoint(sm) {
        height: 62px;
        padding: 12px 17px;

        &--all {
          align-items: flex-start;
        }
      }
      @include breakpoint(lg) {
        padding: 12px 28px 12px 23px;
      }
      @include breakpoint(xl) {
        padding: 12px 20px 12px 17px;
      }

      &--all {
        align-items: center;
        background-color: $blueLight;
      }

      .dark-theme & {
        background: $dark-2;
        border: 1px solid $dark-2;
      }
    }

    &Name {
      color: $titleColor;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      max-width: 85px;
      text-align: left;

      .dark-theme & {
        color: $darkgrayHover;
      }
    }

    &Count {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;

      .dark-theme & {
        color: #b6c2e3;
      }
    }
  }

  &__button {
    background-color: $light;
    border: 0;
    color: $blue;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: -8px;
    padding: 0 10px;
    vertical-align: top;

    .dark-theme & {
      background-color: $dark-3;
    }
  }
}
