.support {
  &Description {
    &__item {
      padding: 12px;
      text-align: flex-start;
      width: 100%;
      @include breakpoint(sm) {
        display: flex;
      }

      &Title {
        color: $defaultColor;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 4px;
        @include breakpoint(sm) {
          margin-bottom: 0;
          width: 40%;
        }
        @include breakpoint(md) {
          width: 30%;
        }
        @include breakpoint(lg) {
          width: 25%;
          font-size: 13px;
        }

        .dark-theme & {
          color: $white;
        }
      }

      &Value {
        color: $gray;
        font-size: 14px;
        line-height: 21px;
        @include breakpoint(sm) {
          width: 60%;
        }
        @include breakpoint(md) {
          width: 90%;
        }
        @include breakpoint(lg) {
          width: 100%;
          font-size: 13px;
        }

        .dark-theme & {
          color: $blueGrayLight;
        }
      }
    }
  }
}

.tasksUsers__listWrap--hidden {
  @include breakpoint(sm) {
    display: block;
  }
}

.tasksUsers__button {
  @include breakpoint(sm) {
    display: none;
  }
}
