.accesses {
  &__form {
    margin-bottom: 29px;
    max-width: 478px;
    position: relative;
    @include breakpoint(sm) {
      margin-bottom: 20px;
      min-width: 303px;
    }
    @include breakpoint(md) {
      min-width: 477px;
      max-width: unset;
    }
  }

  &__input {
    background-color: transparent;
    border: 1px solid $blueLight;
    border-radius: 7px;
    color: $titleColor;
    font-family: $secondary;
    font-size: 14px;
    line-height: 16px;
    padding: 18px 50px 16px 26px;
    transition: all 0.3s ease;
    width: 100%;

    .dark-theme & {
      background-color: $dark;
      border: 1px solid $darkgrayHover;
      color: $white;
    }

    &::placeholder {
      color: $blueGrayLight;
      opacity: 1;
      transition: all 0.3s ease;

      .dark-theme & {
        color: #a0aac6;
      }
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__button {
    align-items: center;
    background-color: $white;
    border: 0;
    border-radius: 7px;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 1px;
    top: 1px;
    transition: all 0.3s ease;
    width: 50px;

    svg {
      fill: $grayDark;
      flex-shrink: 0;
    }

    .dark-theme & {
      background-color: $dark;

      svg {
        fill: $darkgrayHover;
      }
    }
  }

  &__checkboxGroup {
    margin-bottom: 30px;
    @include breakpoint(sm) {
      display: flex;
      align-items: center;
    }

    .checkbox {
      @include breakpoint(sm) {
        margin-left: 30px;
      }
    }
  }

  &-showCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 7px;
    margin-left: 10px;
    @include breakpoint(sm) {
      display: none;
    }

    &.-open {
      background-color: $blue;

      path {
        fill: $white;
      }
    }
  }

  &__header {
    @include breakpoint(sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1280px;
    }

    .accesses__input {
      max-height: 40px;
      font-size: 14px;
      @include breakpoint(sm) {
        max-height: 50px;
      }
    }

    .accesses__button {
      max-height: 38px;
      max-width: 40px;
      @include breakpoint(sm) {
        max-width: unset;
        max-height: 48px;
      }
    }
  }
}
