.menuSectionList {
  flex-shrink: 0;
  max-width: 1440px;
  padding: 20px 0;
  position: relative;
  text-align: center;
  width: 100%;

  @include breakpoint(sm) {
    padding: 60px 0 20px 0;
  }

  &Wrap {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    @include breakpoint(sm) {
      margin: 0 auto;
      max-width: 900px;
    }

    @include breakpoint(md) {
      max-width: inherit;
    }
  }

  &__slider {
    @include breakpoint(sm) {
      padding-right: 11px;
    }

    @include breakpoint(md) {
      padding-right: 0;
    }
  }

  &__item {
    display: inline-block;
    padding: 15px 20px;
    vertical-align: top;
    width: 100%;

    @include breakpoint(sm) {
      margin-bottom: 26px;
      padding: 0 12px 0 13px;
      width: 309px !important;
    }

    @include breakpoint(md) {
      margin-bottom: 39px;
      padding: 0 10px;
      width: 23.438% !important;
    }

    @include breakpoint(lg) {
      padding: 0 13px;
      width: 23.971% !important;
    }

    @include breakpoint(xl) {
      padding: 0 14px;
      width: 24.098% !important;
    }

    > &.withList {
      min-height: 305px;
    }

    &Wrap {
      display: block;
      position: relative;
      text-decoration: none;

      &::before {
        background: $white;
        border-radius: 15px;
        content: "";
        height: 100%;
        left: 10px;
        position: absolute;
        top: 10px;
        transition: background 0.5s ease;
        width: 100%;

        @include breakpoint(sm) {
          display: none;
        }

        .dark-theme & {
          background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
        }
      }
    }

    &Content {
      background: $white;
      border: 1px solid transparent;
      border-radius: 15px;
      box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease;
      z-index: 1;

      @include breakpoint(sm) {
        border: 1px solid $light-2;
        box-shadow: none;

        .dark-theme & {
          border: 1px solid transparent;
        }
      }

      @include breakpoint(md) {
        &:hover {
          box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
        }

        .dark-theme &:hover {
          box-shadow: 5px 5px 10px $darkMedium;
        }
      }

      .dark-theme & {
        background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
        box-shadow: 5px 5px 10px $darkMedium;
      }

      .withList & {
        background: $blue;
        min-height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 331px;
      }
    }

    &Caption {
      align-items: center;
      color: $gray;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 79px;
      justify-content: center;
      line-height: 22px;
      padding: 6px 10px 0;
      transition: color 0.5s ease;

      @include breakpoint(sm) {
        font-size: 18px;
        line-height: 25px;
        padding: 7px 10px 0;
      }

      @include breakpoint(md) {
        font-size: 14px;
        height: 70px;
        line-height: 19px;
        padding: 14px 10px 0;

        .menuSectionList__itemContent:hover & {
          color: $grayHover;
        }

        .dark-theme .menuSectionList__itemContent:hover & {
          color: $white;
        }
      }

      @include breakpoint(lg) {
        font-size: 18px;
        height: 94px;
        line-height: 25px;
        padding: 18px 10px 0;
      }

      @include breakpoint(xl) {
        height: 92px;
        padding: 5px 10px 0;
      }

      .dark-theme & {
        color: $darkgrayHover;
      }
    }
  }
}

.menuList {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;

  &_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    margin: 24px;
    color: $white;
  }

  &_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    gap: 8px;
  }

  &_link {
    height: 48px;
    padding: 16px 24px;
    color: $white;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.6px;
    text-align: left;
    text-decoration-skip-ink: none;
    text-decoration: none;
    display: inline-block;
    border: 1px solid $white;
    border-radius: 5px;

    @include hover-focus-active {
      background-color: $white;
      color: $blue;
      transition: 0.3s all ease-in-out;
    }
  }
}
