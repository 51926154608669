.title {
  color: $titleColor;
  font-weight: 600;
  transition: color 0.5s ease;

  .dark-theme & {
    color: $white;
  }

  &--main {
    font-size: 24px;
    left: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 31px;
    width: 100%;
    z-index: 1;
    @include breakpoint(sm) {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 22px;
      margin-top: 7px;
      position: static;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $titleColor;

  .dark-theme & {
    color: $white;
  }
}

h2 {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
  margin-top: 20px;
  @include breakpoint(sm) {
    font-size: 22px;
    line-height: 30px;
  }
}
