.menuSectionList__itemIcon {
  padding-top: 36px;
  width: 100%;
  @include breakpoint(md) {
    padding-top: 20px;
  }
  @include breakpoint(lg) {
    padding-top: 10px;
  }

  i {
    @include breakpoint(xl) {
    }
    align-items: flex-end;
    display: flex;
    height: 211px;
    justify-content: center;
    width: 100%;
    @include breakpoint(sm) {
      height: 214px;
    }
    @include breakpoint(md) {
      height: 166px;
      transition: transform 0.5s ease;

      .bx-no-touch .menuSectionList__itemContent:hover & {
        transform: scale(1.05);
      }
    }
    @include breakpoint(lg) {
      height: 227px;
    }
  }

  svg {
    flex-shrink: 0;
    max-height: 90%;
    max-width: 90%;

    path {
      transition: all 0.5s ease;
    }

    .dark-theme & .icon-color-black {
      fill: $dark;
    }

    .dark-theme & .icon-color-white {
      fill: $white;
    }

    .dark-theme & .icon-color-gray {
      fill: $dark-2;
    }

    .dark-theme & .icon-color-transparent {
      fill: transparent;
    }
  }
}
