.oftenUsed {
  margin: 0 -20px;
  position: relative;
  @include breakpoint(sm) {
    margin: 0 0 0 -4px;
    min-width: 100%;
  }
  @include breakpoint(md) {
    margin: 0;
    min-width: inherit;
    width: calc(100% - 351px);
  }

  &__wrap {
    padding: 0 16px;
    padding-bottom: 21px;
    position: relative;
    z-index: 1;
    @include breakpoint(sm) {
      padding: 0;
    }
    @include breakpoint(md) {
      max-width: 913px;
      width: 100%;
    }
  }

  &__table {
    background-color: $white;
    border-radius: 15px;
    box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
    margin-bottom: 52px;
    margin-top: 19px;
    transition: all 0.5s ease;
    width: 100%;
    @include breakpoint(sm) {
      margin-bottom: 10px;
    }
    @include breakpoint(md) {
      margin-bottom: 0;
      margin-left: 15px;
      margin-top: 1px;
    }

    .dark-theme & {
      background-color: $dark-2;
      box-shadow: 5px 5px 10px $darkMedium;
    }

    &Row {
      position: relative;
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease;
      width: 100%;
      padding: 10px 20px 30px 10px;
      box-shadow: none;
      @include breakpoint(sm) {
        flex-direction: row;
        padding: 0;
      }

      &--head {
        padding: 0;
      }
    }

    &Col {
      color: $defaultColor;
      display: inline-flex;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      position: relative;
      transition: all 0.5s ease;
      padding: 17px 0 0 20px;
      @include breakpoint(sm) {
        padding: 15px 10px 19px 19px;
      }

      .dark-theme & {
        color: $white;
      }

      &::after {
        @include breakpoint(sm) {
          border-bottom: 1px solid $blueLight;
          bottom: 0;
          content: "";
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          transition: all 0.5s ease;
        }

        .dark-theme & {
          border-bottom: 1px solid $dark;
        }

        .oftenUsed__tableRow:first-of-type &,
        .oftenUsed__tableRow:last-of-type & {
          display: none;
        }
      }

      &:first-of-type {
        &::after {
          left: 17px;
        }
      }

      &:last-of-type {
        &::after {
          right: 10px;
        }
      }

      .oftenUsed__tableRow:first-of-type & {
        &:first-of-type {
          border-radius: 15px 0 0;
        }

        &:last-of-type {
          border-radius: 0 15px 0 0;
        }
      }

      .oftenUsed__tableRow:last-of-type & {
        &:last-of-type {
          border-radius: 0 0 15px;
        }

        &:first-of-type {
          border-radius: 0 0 0 15px;
        }
      }

      &--head {
        align-items: center;
        background-color: $blue;
        color: $white;
        height: 60px;
        padding-bottom: 0;
        padding-top: 3px;
        transition: all 0.5s ease;
        display: none;
        @include breakpoint(sm) {
          display: flex;
        }

        .dark-theme & {
          background-color: $darkgrayHover;
        }

        &Mobile {
          display: flex;
          justify-content: center;
          align-items: baseline;
          height: 50px;
          background-color: $blue;
          color: $white;
          border-radius: 15px 15px 0 0;
          @include breakpoint(sm) {
            display: none;
          }

          svg {
            margin-right: 8px;

            path {
              fill: $white;
            }
          }
        }
      }

      &--gray {
        background-color: $light;
        color: $blueGrayLight;

        .dark-theme & {
          background-color: #27272b;
          color: $blueGrayLight;
        }
      }

      &--type {
        width: 100%;
        @include breakpoint(sm) {
          padding-left: 27px;
          width: 21%;
        }

        .d-sm-flex {
          width: 100%;
          display: flex;
          justify-content: space-between;
          @include breakpoint(sm) {
            width: auto;
            justify-content: stretch;
          }

          .burger {
            order: 1;
            margin-right: 0;
            @include breakpoint(sm) {
              order: unset;
              margin-right: 17px;
            }
          }
        }
      }

      &--host {
        width: 100%;
        @include breakpoint(sm) {
          width: 20%;
        }
      }

      &--login {
        width: 100%;
        @include breakpoint(sm) {
          width: 25%;
        }
      }

      &--pass {
        flex-grow: 1;
        @include breakpoint(sm) {
          padding-right: 60px;
        }
      }

      &--actions {
        position: absolute;
        width: auto;
        right: 20px;
        bottom: 30px;
        @include breakpoint(sm) {
          position: relative;
          right: unset;
          bottom: unset;
          padding-right: 25px;
        }

        path {
          fill: transparent;
          stroke: $grayDark;
        }

        .likeIcon {
          path {
            stroke: #ffbfe6;
            fill: #ffbfe6;
          }
        }

        button {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &Inner {
        display: flex;
        align-items: center;
        position: relative;

        a {
          color: $blue;
          text-decoration: none;
        }

        span {
          display: none;
        }
      }
    }
  }

  .scrollbar-container {
    z-index: 1;
    @include breakpoint(sm) {
      overflow: visible !important;
    }
  }

  .ps__rail-x {
    border-radius: 5px;
    bottom: 31px !important;
    height: 3px;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 1;
    z-index: 2;
    @include breakpoint(sm) {
      display: none !important;
    }
  }

  .ps__thumb-x {
    background-color: $blue;
    border-radius: 5px;
    bottom: 0;
    height: 3px;

    .dark-theme & {
      background-color: #a0aac6;
    }
  }

  .ps:hover > .ps__rail-x,
  .ps--focus > .ps__rail-x,
  .ps--scrolling-x > .ps__rail-x {
    opacity: 1;
  }

  .ps--active-x > .ps__rail-x {
    background-color: $white;

    .dark-theme & {
      background-color: $black;
    }
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: $blue;
    height: 3px;

    .dark-theme & {
      background-color: #a0aac6;
    }
  }
}
