.tasksItems {
  padding-bottom: 65px;
  padding-top: 29px;
  @include breakpoint(sm) {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 48px;
  }
  @include breakpoint(md) {
    padding-bottom: 55px;
  }
  @include breakpoint(lg) {
    padding-bottom: 32px;
  }

  &__title {
    color: $titleColor;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 6px;
    padding-left: 10px;
    @include breakpoint(sm) {
      font-size: 22px;
      line-height: 30px;
      padding-left: 0;
    }

    span {
      white-space: nowrap;
    }
  }

  &__sort {
    margin-bottom: 6px;
    padding-left: 10px;
    @include breakpoint(md) {
      padding-right: 20px;
    }
    @include breakpoint(lg) {
      padding-right: 0;
    }

    &Title {
      color: $gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      @include breakpoint(md) {
        padding-right: 4px;
      }
    }

    .styledSelect {
      display: inline-block;
      vertical-align: baseline;
      width: auto;

      .styledSelect {
        &__control {
          background-color: transparent;
          border: 0 !important;
          min-height: inherit;
          max-width: 300px;
        }

        &__value-container {
          padding: 0 0 0 7px;
          width: auto;

          input {
            position: absolute;
          }
        }

        &__single-value {
          font-size: 14px;
          line-height: 19px;
          position: relative;
          top: 0;
          transform: none;
        }

        &__indicator {
          padding: 0 0 0 2px;

          svg {
            height: 22px;
            width: 22px;
          }
        }

        &__menu {
          border-top: 1px solid $blueLight;
          right: 0;
          width: auto;

          .dark-theme & {
            border-top: 1px solid $darkgrayHover;
          }
        }
      }
    }
  }

  &__list {
    margin: 0 -10px;
    padding: 8px 0;
    @include breakpoint(sm) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -11px;
      padding: 16px 0;
      width: calc(100% + 22px);
    }
    @include breakpoint(md) {
      margin: 0 -7px;
      width: calc(100% + 14px);
    }
    @include breakpoint(lg) {
      margin: 0 -13px;
      width: calc(100% + 26px);
    }
    @include breakpoint(xl) {
      padding: 15px 0;
    }
  }

  &__item {
    margin-bottom: 9px;
    position: relative;
    transition: all 0.5s ease;
    width: 100%;
    @include breakpoint(sm) {
      padding: 0 11px;
      width: 50%;
    }
    @include breakpoint(md) {
      padding: 0 7px;
    }
    @include breakpoint(lg) {
      padding: 0 13px;
      width: 33.333%;
    }
    @include breakpoint(xl) {
      margin-bottom: 19px;
    }

    &Wrap {
      background: $white;
      border: 1px solid $blueLight;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 172px;
      height: 100%;
      padding: 32px 19px 20px;
      text-decoration: none;
      transition: all 0.5s ease;
      @include breakpoint(lg) {
        min-height: 212px;
        padding: 32px 19px 40px;
      }

      .dark-theme & {
        background: $dark-2;
        border: 1px solid $dark-2;
      }

      .tasksItems__item--active &,
      .dark-theme .tasksItems__item--active & {
        border: 1px solid $orange;
      }

      &:hover {
        border: 1px solid $white;
        border-radius: 15px;
        box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px #fff;
      }

      .dark-theme &:hover {
        border: 1px solid $dark-2;
        box-shadow: 5px 5px 10px $darkMedium;
      }
    }

    &Row {
      display: flex;
      justify-content: space-between;

      &:last-of-type {
        align-items: center;
      }
    }

    &Data {
      width: 65%;
    }

    &Title {
      width: 80%;
      color: $titleColor;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      padding-right: 20px;
      padding-bottom: 15px;
      @include breakpoint(sm) {
        padding-right: 25px;
      }
      @include breakpoint(xl) {
        font-size: 15px;
        line-height: 24px;
        padding-right: 35px;
      }

      .tasksItems__itemWrap:hover & {
        color: $grayHover;
      }

      .dark-theme & {
        color: $white;
      }

      .dark-theme .tasksItems__itemWrap:hover & {
        color: $white;
      }
    }

    &Code {
      color: $blue;
      flex-shrink: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
      @include breakpoint(sm) {
        min-width: 45px;
      }
      @include breakpoint(md) {
        min-width: 51px;
      }
      @include breakpoint(xl) {
        min-width: 57px;
      }

      .tasksItems__itemWrap:hover & {
        color: $grayHover;
      }

      .dark-theme & {
        color: $white;
      }

      .dark-theme .tasksItems__itemWrap:hover & {
        color: $white;
      }
    }
  }
}
