.accessDetail__item {
  position: relative;
  box-shadow: none;
  display: flex;
  transition: box-shadow 0.5s ease, border-radius 0.5s ease;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: $white;
  border-radius: 15px;
  padding: 10px 20px 30px 10px;

  @include breakpoint(sm) {
    flex-direction: row;
    margin-bottom: 0;
    padding: 0;
  }
  @include breakpoint(md) {
    &:not(.accessDetail__item--head):hover {
      border-radius: 15px;
      box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
      position: relative;
      z-index: 1;

      & + .accessDetail__item .accessDetail__itemCol::after,
      .accessDetail__itemCol::after {
        display: none;
      }

      .accessDetail__itemCol--type {
        background-color: $blue;
        color: $white;
        border-radius: 15px;

        a {
          color: $white;
        }
      }

      .accessDetail__itemName,
      .accessDetail__itemSite {
        color: $grayHover;
      }

      .burger-head rect {
        fill: $white;
      }

      + .accessDetail__item {
        position: relative;
        z-index: -1;
      }
    }

    .dark-theme &:not(.accessDetail__item--head):hover {
      box-shadow: 5px 5px 10px $darkMedium;

      .accessDetail__itemCol,
      .accessDetail__itemDate,
      .accessDetail__itemName,
      .accessDetail__itemSite {
        color: $white;
      }

      .accessDetail__itemCol--type {
        background-color: $darkgrayHover;
      }
    }
  }

  .d-sm-flex,
  .hideText::after,
  .accessDetail__itemInner {
    align-items: flex-start !important;
  }

  .dark-theme & {
    background-color: $blueGray;
  }

  &Inner {
    // align-items: center;
    display: flex;
    word-break: break-word;
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }

    a {
      color: $blue;
      text-decoration: none;
      transition: all 0.5s ease;

      .dark-theme & {
        color: $darkgrayHover;
      }
    }
  }

  &--head {
    display: none;
    @include breakpoint(sm) {
      display: flex;
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 1;
    }

    .dark-theme & {
      background-color: $blueGray;
    }
  }

  &.accessDenied {
    opacity: 0.8;
    pointer-events: none;

    .requestIcon {
      z-index: 12;
      pointer-events: all;
    }

    .accessDetail__itemCol--type {
      background-color: transparent !important;

      .accessDetail__itemInner {
        color: $blueGrayLight;
      }

      svg {
        rect {
          fill: $blueGrayLight !important;
        }
      }
    }
  }
}

.display {
  display: none;
}

.ssh {
  cursor: pointer;
}

.accessDetail__largeNotice {
  height: 170px;
  margin-bottom: 10px;
}

.accessDetail__fullHeight {
  height: 100%;
}

.large__notice {
  height: 125px;
  overflow: hidden;
}

.show__notice {
  display: flex;
  color: #3262e0;

  svg {
    width: 12px;
    height: 12px;
    transform: rotate(90deg);
    color: #3262e0;
    margin: 3px 0 0 10px;
  }

  &:hover {
    cursor: pointer;
  }
}

.arrow__noticeUp {
  svg {
    transform: rotate(270deg);
  }
}
.wrapper__comment {
  display: block;
}
