.post__structure {
  border: 1px solid $blueLight;
  border-radius: 10px;
  transition: all 0.5s ease;
  margin-bottom: 50px;
  @include breakpoint(sm) {
    border-radius: 7px;
  }

  &Control {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &Button {
    align-items: center;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: $blue;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    font-family: $default;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    min-height: 50px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 0 0 20px;
    position: relative;
    @include breakpoint(sm) {
      padding: 0 0 0 25px;
    }

    .dark-theme & {
      color: #87a4f2;
    }
  }

  &Arrow {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    padding: 0 17px;
    @include breakpoint(sm) {
      padding: 5px 19px 5px 5px;
    }

    svg {
      fill: $blue;
      height: 22px;
      transition: transform 0.3s ease;
      width: 22px;

      .styledSelect__control--menu-is-open & {
        transform: rotateX(180deg);
      }

      .dark-theme & {
        fill: $darkgrayHover;
      }
    }
  }

  &List {
    display: none;
    margin: 0;
    padding: 9px 20px 14px;
    @include breakpoint(sm) {
      padding: 9px 25px 14px;
    }

    .is-open & {
      display: block;
    }
  }

  &Item {
    list-style-type: none;
    margin-bottom: 5px;
  }

  &Parent {
    background-color: transparent;
    border: 0;
    color: $blue;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    padding: 0;
    text-decoration: none;

    .dark-theme & {
      color: #87a4f2;
    }
  }

  &Children {
    counter-reset: point;
    margin-left: 20px;
    margin-top: 9px;
    padding: 0;

    &Item {
      display: block;
      margin-bottom: 1px;
    }

    &Link {
      background-color: transparent;
      border: 0;
      color: $blue;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      line-height: 19px;
      padding: 0 0 0 16px;
      position: relative;
      text-decoration: none;
      vertical-align: top;

      &::before {
        content: counter(point) ".";
        counter-increment: point;
        left: 0;
        position: absolute;
        top: 0;
      }

      .dark-theme & {
        color: #87a4f2;
      }
    }
  }
}
