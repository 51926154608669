.search {
  margin-bottom: 45px;
  position: relative;
  width: 100%;
  @include breakpoint(sm) {
    margin-bottom: 0;
  }

  &__form {
    position: relative;
  }

  &__input {
    background-color: #404962;
    border: 0;
    border-radius: 7px;
    box-shadow: 5px 5px 10px #212f53;
    color: $headerColorWhite;
    font-family: $secondary;
    font-size: 14px;
    line-height: 16px;
    padding: 18px 18px 16px 57px;
    transition: all 0.3s ease;
    width: 100%;
    @include breakpoint(lg) {
      padding: 18px 18px 16px 49px;
    }
    @include breakpoint(xl) {
      padding: 18px 18px 16px 56px;
    }

    &.border-active {
      border-radius: 7px 7px 0 0;
      box-shadow: none;
    }

    &:focus {
      color: $titleColor;
      border-bottom: 0;
    }

    .dark-theme & {
      background-color: $dark-2;
      box-shadow: 5px 5px 10px $darkMedium;
      color: $white;

      &.border-active {
        border-radius: 7px 7px 0 0;
        box-shadow: none;
      }

      &:focus {
        background-color: $dark-2;
      }
    }

    .dark-theme .-filled & {
      background-color: $dark-2;
    }

    .-filled &,
    &:focus {
      background-color: $white;

      & + .search__btn svg {
        fill: $blue;
      }

      .dark-theme & + .search__btn svg {
        fill: $white;
      }
    }

    &::placeholder {
      color: $headerColor;
      opacity: 1;
      transition: all 0.3s ease;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  &__btn {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    display: block;
    height: 19px;
    left: 23px;
    margin-top: -9px;
    padding: 0;
    position: absolute;
    top: 50%;
    transition: all 0.3s ease;
    width: 19px;
    @include breakpoint(lg) {
      left: 15px;
    }
    @include breakpoint(xl) {
      left: 22px;
    }

    svg {
      fill: $headerColor;
    }
  }

  &__suggests {
    background: $white;
    border: 2px solid $light-2;
    border-radius: 0 0 7px 7px;
    left: 0;
    display: none;
    position: absolute;
    top: 100%;
    transform: translate3d(0, -5%, 0);
    transition: transform 0.3s ease;
    visibility: hidden;
    width: 100%;
    z-index: 10000;

    .-active & {
      display: block;
      transform: none;
      visibility: visible;
    }

    .dark-theme & {
      background-color: $dark-2;
      border: 2px solid #3f3f41;
    }

    &::before {
      background: $white;
      content: "";
      display: block;
      height: 10px;
      left: -1px;
      position: absolute;
      top: -7px;
      width: calc(100% + 2px);

      .dark-theme & {
        background: $dark-2;
      }
    }

    &::after {
      background: $white;
      border-bottom: 1px solid $blueLight;
      content: "";
      display: block;
      height: 0;
      left: 21px;
      position: absolute;
      right: 21px;
      top: -2px;

      .dark-theme & {
        border-bottom: 1px solid $dark-3;
      }
    }

    &List {
      padding: 0 21px;
    }

    &Item {
      border-bottom: 1px solid $blueLight;
      padding: 21px 0 18px;

      .dark-theme & {
        border-bottom: 1px solid $dark-3;
      }

      &Cat {
        color: $headerColor;
        display: inline-block;
        font-family: $default;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 2px;
        transition: all 0.3s ease;
        vertical-align: top;

        &--last {
          margin-left: 19px;
        }

        .header__suggestsItemLink:hover & {
          color: $gray;
        }

        .dark-theme & {
          color: $white;
        }

        .dark-theme .header__suggestsItemLink:hover & {
          color: $white;
        }
      }

      &Caption {
        font-family: $default;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }

    &Total {
      background-color: transparent;
      border: 0;
      color: $blue;
      cursor: pointer;
      display: inline-block;
      font-family: $default;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 28px;
      margin-top: 13px;
      padding: 0;
      text-decoration: none;
      transition: all 0.3s ease;
      vertical-align: top;

      &:hover {
        color: $grayHover;
      }

      .dark-theme & {
        color: $darkgrayHover;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

.search__suggestsItemLink {
  color: $blue;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    color: $darkMedium;
  }

  &.keyboard_focus {
    color: $darkMedium;
  }

  .dark-theme & {
    color: $darkgrayHover;

    &:hover {
      color: $white;
    }

    &.keyboard_focus {
      color: $white;
    }
  }
}
