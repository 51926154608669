.breadCrumbs {
  width: 100%;
  @include breakpoint(sm) {
    width: calc(100% - 235px);
  }

  &__list {
    box-orient: vertical;
    color: $headerColor;
    display: flex;
    align-items: baseline;
    font-size: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    line-height: 16px;
    padding-left: 0;
    @include breakpoint(sm) {
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
    }

    .dark-theme & {
      color: $gray;
    }
  }

  &__item {
    display: inline;
    line-height: 14px;
    margin: 0;
    padding-right: 8px;
    position: relative;
    white-space: nowrap;
    @include breakpoint(sm) {
      line-height: 16px;
      padding-right: 9px;
    }
  }

  &__link {
    color: $gray;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;

    .dark-theme & {
      color: $darkgrayHover;
    }

    &--next {
      color: $darkgrayHover;
      font-size: 16px !important;
    }

    &--last {
      color: $headerColor;

      .dark-theme & {
        color: $gray;
      }
    }
  }
}
