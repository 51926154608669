$fontsPath: "../fonts";

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url("#{$fontsPath}/Roboto/Roboto-Thin.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Thin.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  src: url("#{$fontsPath}/Roboto/Roboto-ThinItalic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("#{$fontsPath}/Roboto/Roboto-Light.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Light.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url("#{$fontsPath}/Roboto/Roboto-LightItalic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-LightItalic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url("#{$fontsPath}/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  src: url("#{$fontsPath}/Roboto/Roboto-Italic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Italic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("#{$fontsPath}/Roboto/Roboto-Medium.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url("#{$fontsPath}/Roboto/Roboto-MediumItalic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  src: url("#{$fontsPath}/Roboto/Roboto-Bold.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  src: url("#{$fontsPath}/Roboto/Roboto-BoldItalic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url("#{$fontsPath}/Roboto/Roboto-Black.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-Black.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: url("#{$fontsPath}/Roboto/Roboto-BlackItalic.woff2") format("woff2"),
    url("#{$fontsPath}/Roboto/Roboto-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: normal;
  font-weight: normal;
  src: url("#{$fontsPath}/Noto_Sans/notosans.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosans.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: italic;
  font-weight: normal;
  src: url("#{$fontsPath}/Noto_Sans/notosansitalic.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosansitalic.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: normal;
  font-weight: 500;
  src: url("#{$fontsPath}/Noto_Sans/notosansmedium.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosansmedium.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: italic;
  font-weight: 500;
  src: url("#{$fontsPath}/Noto_Sans/notosansmediumitalic.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosansmediumitalic.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: normal;
  font-weight: 600;
  src: url("#{$fontsPath}/Noto_Sans/notosanssemibold.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosanssemibold.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: italic;
  font-weight: 600;
  src: url("#{$fontsPath}/Noto_Sans/notosanssemibolditalic.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosanssemibolditalic.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: normal;
  font-weight: bold;
  src: url("#{$fontsPath}/Noto_Sans/notosansbold.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosansbold.woff") format("woff");
}

@font-face {
  font-family: Noto_Sans;
  font-style: italic;
  font-weight: bold;
  src: url("#{$fontsPath}/Noto_Sans/notosansbolditalic.woff2") format("woff2"),
    url("#{$fontsPath}/Noto_Sans/notosansbolditalic.woff") format("woff");
}
