.post__bottom {
  border-top: 1px solid $blueLight;
  padding-top: 17px;
  @include breakpoint(sm) {
    padding-top: 19px;
  }
  @include breakpoint(md) {
    padding-top: 17px;
  }
  @include breakpoint(lg) {
    margin-top: 6px;
    padding-top: 26px;

    h5 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px;
    }
  }

  .dark-theme & {
    border-top: 1px solid $dark;
  }

  h5 {
    color: $captionColor;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 12px;
    margin-top: 0;
  }

  &Row {
    padding-top: 18px;
    @include breakpoint(md) {
      display: flex;
    }
    @include breakpoint(lg) {
      padding-bottom: 21px;
    }
  }

  &Col {
    margin-bottom: 28px;
    @include breakpoint(md) {
      margin-bottom: 20px;
      padding-left: 18px;
      padding-right: 10px;
      width: 33.3333%;

      &:first-of-type {
        padding-left: 0;
      }
    }
    @include breakpoint(lg) {
      padding-left: 25px;
      padding-right: 35px;
    }
    @include breakpoint(xl) {
      margin-bottom: 10px;
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  &Actions {
    padding-top: 23px;
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }
    @include breakpoint(md) {
      flex-shrink: 0;
      padding-top: 0;
      width: 528px;
    }
    @include breakpoint(lg) {
      width: 651px;
    }
  }
}

.post__tags {
  line-height: 21px;

  &Item {
    color: $darkgrayHover;
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
    text-decoration: none;
    vertical-align: top;
  }
}

.post__related {
  &Item {
    margin-bottom: 4px;
  }

  &Link {
    color: $blue;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
  }
}

.post__links {
  &Item {
    margin-bottom: 4px;
  }

  &Link {
    color: $darkgrayHover;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    transition: all 0.5s ease;

    .dark-theme &:hover {
      color: $white;
    }
  }
}

.post__info {
  color: $gray;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  @include breakpoint(md) {
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
    width: calc(100% - 528px);

    &Item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &User {
      width: calc(100% - 161px);
    }
  }
  @include breakpoint(lg) {
    width: calc(100% - 651px);

    &Date {
      width: 171px;
    }

    &User {
      width: calc(100% - 171px);
    }
  }

  .dark-theme & {
    color: #b6c2e3;
  }

  &Item {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }

  &Date {
    flex-shrink: 0;
    white-space: nowrap;
    width: 118px;

    &Text {
      &--mob {
        display: inline;
      }

      &--desk {
        display: none;
      }
    }
  }

  &User {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 118px);
  }
}

.favorite {
  padding: 0;
  width: 100%;
  @include breakpoint(sm) {
    margin-right: 10px;
    width: calc(100% - 175px);
  }
  @include breakpoint(md) {
    width: calc(100% - 370px);
  }
}

.post__rating {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  width: 100%;
  @include breakpoint(sm) {
    flex-shrink: 0;
    padding-top: 0;
    width: 175px;
  }
  @include breakpoint(md) {
    justify-content: flex-end;
    width: 175px;
  }
}

.post__infoDate {
  width: 161px;

  &Text {
    &--mob {
      display: none;
    }

    &--desk {
      display: inline;
    }
  }
}

.post__ratingItem {
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 2;
  @include breakpoint(md) {
    &Wrap {
      padding: 15px 14px 13px 45px;
    }

    &Text {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 2px;
    }

    &Icon {
      display: block;
    }
  }

  &Wrap {
    border-radius: 5px;
    cursor: pointer;
    padding: 14px 14px 13px 15px;

    position: relative;
    z-index: 1;
    @include breakpoint(xs) {
      padding: 14px 14px 13px 55px;
    }

    .post__rating--voted & {
      cursor: default;
    }

    .post__rating--voted .post__ratingItem--dislike &,
    .post__rating--voted .post__ratingItem--dislike:hover &,
    .post__ratingItem--dislike & {
      background-color: #ffbfe5;
    }

    .post__rating--voted .post__ratingItem--like &,
    .post__rating--voted .post__ratingItem--like:hover &,
    .post__ratingItem--like & {
      background-color: #d0ffbf;
    }
  }

  &--voted {
    animation: zoom 0.5s ease-in-out;
  }

  &--like {
    .post__ratingItem--voted.post__ratingItemWrap {
      background-color: $greenLight !important;
    }

    &:hover .post__ratingItemWrap {
      background-color: $greenLight;
    }
  }

  &--dislike {
    .post__ratingItem--voted.post__ratingItemWrap {
      background-color: #ff93c0 !important;
    }

    &:hover .post__ratingItemWrap {
      background-color: #ff93c0;
    }
  }

  &Text {
    color: $titleColor;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  &Like {
    color: $gray;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }

  &Icon {
    display: none;
    @include breakpoint(xs) {
      left: 0;
      margin-top: -25px;
      position: absolute;
      top: 50%;
      display: block;
    }

    svg {
      display: inline-block;
      vertical-align: top;
    }

    .post__ratingItem--dislike & {
      left: 17px;
      margin-top: -8px;

      svg {
        height: 15px;
        width: 15px;
      }
    }

    .post__ratingItem--like & {
      left: 11px;
      margin-top: -13px;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  &Users {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
    left: 0;
    opacity: 0;
    padding: 75px 16px 16px 20px;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;

    .dark-theme & {
      background-color: $dark-2;
      box-shadow: 5px 5px 10px $darkMedium;
    }

    &Wrap {
      max-height: 88px;
    }

    &Caption {
      color: $headerColor;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 7px;
    }

    &Item {
      color: $gray;
      font-size: 12px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .dark-theme & {
        color: #a0aac6;
      }
    }

    .ps__rail-y {
      opacity: 1;
      width: 2px;
    }

    .ps__thumb-y {
      background-color: #bcdaf9;
      right: 0;
      width: 2px;

      .dark-theme & {
        background-color: #a0aac6;
      }
    }

    .ps:hover > .ps__rail-y,
    .ps--focus > .ps__rail-y,
    .ps--scrolling-y > .ps__rail-y {
      opacity: 1;
    }

    .ps--active-y > .ps__rail-y {
      background-color: #e5f1fd;

      .dark-theme & {
        background-color: $black;
      }
    }

    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
      background-color: #bcdaf9;
      width: 2px;

      .dark-theme & {
        background-color: #a0aac6;
      }
    }
  }

  &:hover .post__ratingItemUsers {
    opacity: 1;
    visibility: visible;
  }
}
