.content {
  .react-datepicker-wrapper {
    width: max-content;
    position: relative;

    input {
      background-color: $white;
      border-radius: 10px;
      border: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      color: $blue;
      padding: 8px 0 8px 50px;
      max-height: 40px;
      max-width: 210px;

      .dark-theme & {
        background-color: $blueGray;
        color: $white;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 24px;
      top: 10px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8333 2.33341H13V0.666748H11.3333V2.33341H4.66667V0.666748H3V2.33341H2.16667C1.24167 2.33341 0.508333 3.08341 0.508333 4.00008L0.5 15.6667C0.5 16.1088 0.675595 16.5327 0.988155 16.8453C1.30072 17.1578 1.72464 17.3334 2.16667 17.3334H13.8333C14.75 17.3334 15.5 16.5834 15.5 15.6667V4.00008C15.5 3.08341 14.75 2.33341 13.8333 2.33341ZM13.8333 15.6667H2.16667V7.33341H13.8333V15.6667ZM13.8333 5.66675H2.16667V4.00008H13.8333V5.66675ZM5.5 10.6667H3.83333V9.00008H5.5V10.6667ZM8.83333 10.6667H7.16667V9.00008H8.83333V10.6667ZM12.1667 10.6667H10.5V9.00008H12.1667V10.6667ZM5.5 14.0001H3.83333V12.3334H5.5V14.0001ZM8.83333 14.0001H7.16667V12.3334H8.83333V14.0001ZM12.1667 14.0001H10.5V12.3334H12.1667V14.0001Z' fill='%233262E0'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;

      .dark-theme & {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8333 2.33341H13V0.666748H11.3333V2.33341H4.66667V0.666748H3V2.33341H2.16667C1.24167 2.33341 0.508333 3.08341 0.508333 4.00008L0.5 15.6667C0.5 16.1088 0.675595 16.5327 0.988155 16.8453C1.30072 17.1578 1.72464 17.3334 2.16667 17.3334H13.8333C14.75 17.3334 15.5 16.5834 15.5 15.6667V4.00008C15.5 3.08341 14.75 2.33341 13.8333 2.33341ZM13.8333 15.6667H2.16667V7.33341H13.8333V15.6667ZM13.8333 5.66675H2.16667V4.00008H13.8333V5.66675ZM5.5 10.6667H3.83333V9.00008H5.5V10.6667ZM8.83333 10.6667H7.16667V9.00008H8.83333V10.6667ZM12.1667 10.6667H10.5V9.00008H12.1667V10.6667ZM5.5 14.0001H3.83333V12.3334H5.5V14.0001ZM8.83333 14.0001H7.16667V12.3334H8.83333V14.0001ZM12.1667 14.0001H10.5V12.3334H12.1667V14.0001Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
    border: 0;
    background: $white;
    border-radius: 10px;
    position: relative;
    z-index: 22;

    .factLoadCosts__btns {
      display: flex;
      align-items: center;
    }

    &__header {
      background-color: $white;
      border-bottom: 1px solid $blueLight;
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }

    &__current-month {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $defaultColor;
      text-transform: capitalize;
    }

    &__navigation {
      &--next {
        right: 30px;
        top: 5px;
      }

      &--previous {
        top: 5px;
        left: 30px;
      }
    }

    &__navigation-icon {
      &::before {
        border-color: $blue;
        border-width: 2px 2px 0 0;
      }
    }

    &__day-name {
      font-weight: 700;
      font-size: 10px;
      line-height: 26px;
      color: $blueGrayLight;

      &:nth-last-of-type(-n + 2) {
        color: $grayDark;
      }
    }

    span.react-datepicker__navigation-icon.react-datepicker__navigation-icon {
      position: absolute;
      top: 3px;
      left: 14px;
    }

    &__day {
      font-weight: 400;
      font-size: 12px;
      line-height: 26px;
      text-align: center;
      color: $gray;

      &--today {
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        color: $white;
        background: #dae4ff;
        border-radius: 15px;
      }

      &--selected {
        color: $white;
        background-color: $blue;
        border-radius: 15px;
      }

      &--keyboard-selected {
        background-color: $white;
      }

      &--outside-month {
        color: #bec5d6;
      }

      &:hover {
        border-radius: 15px;
      }
    }

    &__triangle {
      &::before,
      &::after {
        display: none;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: $white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 14px 21px 20px;

      &-day {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 10px;
        line-height: 24px;
        color: $gray;

        .selected {
          font-weight: 500;
          font-size: 12px;
          line-height: 21px;
          color: $titleColor;
        }
      }

      &-reset {
        svg {
          width: 36px;
          height: 36px;

          circle {
            fill: $blue;
          }

          path {
            fill: $white;
          }
        }
      }
    }

    &-accept {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $blue;
      color: $white;
      height: 75px;
      border-radius: 10px;
      position: absolute;
      padding-top: 38px;
      z-index: -1;
      bottom: -50px;
      cursor: pointer;
    }
  }
}
