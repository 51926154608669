.deleteAccess {
  &__title {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__text {
    color: $gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 23px;
    text-align: center;

    .dark-theme & {
      color: $white;
    }
  }

  &__btns {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__btn {
    align-items: center;
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    color: $titleColor;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 60px;
    justify-content: center;
    line-height: 16px;
    margin: 0 11px 0 10px;
    min-width: 95px;
    padding: 0 15px;
    position: relative;
    white-space: nowrap;
    @include breakpoint(sm) {
      min-width: 158px;
      padding: 0 28px;
    }

    &--reset {
      background: $white;
      border: 1px solid $light-2;

      .dark-theme & {
        background: transparent;
        border: 1px solid $white;
        color: $white;
      }
    }

    &--submit {
      background: #ffbfe6;

      svg {
        fill: $blueGrayLight;
        margin-left: -6px;
        margin-right: 5px;
        @include breakpoint(sm) {
          margin-right: 15px;
        }

        path {
          fill: $white;
        }
      }
    }
  }
}
