.supportCalendar {
  padding-top: 46px;

  .table-wrapper {
    padding: 0;
    overflow-x: auto;
    @include breakpoint(md) {
      overflow: unset;
      padding: 0 40px;
    }
  }

  .styledSelect {
    margin-bottom: 40px;
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
    margin-bottom: 20px;
    @include breakpoint(md) {
      padding: 0 35px;
    }

    &__date {
      color: $blueGrayLight;
      font-size: 11px;
      font-weight: 500;
      line-height: 18px;
      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 26px;
      }

      .dark-theme & {
        color: $darkgrayHover;
      }

      .year {
        display: none;
        @include breakpoint(sm) {
          display: inline-block;
        }
      }
    }
  }

  &-change {
    align-items: center;
    color: $defaultColor;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &__button {
      height: 22px;
      text-decoration: none;
      width: 10px;
      cursor: pointer;
    }

    .next {
      right: -10px;
      transform: rotate(180deg);
    }

    .prev {
      left: -10px;
    }

    .supportCalendar-header__date {
      color: $defaultColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0 10px;

      .dark-theme & {
        color: $white;
      }
    }

    .year {
      color: $defaultColor;
      display: inline-block;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .dark-theme & {
        color: $white;
      }
    }
  }
}

.calendarTable {
  position: relative;
  background-color: $white;
  border: 1px solid $light-2;
  border-radius: 0;
  border-spacing: inherit;
  font-size: 13px;
  line-height: 26px;
  padding: 13px 0;
  width: 100%;
  @include breakpoint(sm) {
    padding: 23px 0;
  }
  @include breakpoint(md) {
    border-radius: 10px;
    padding: 23px 20px;
  }

  thead {
    th:first-child {
      font-weight: 500;
      padding-left: 25px;
      text-align: flex-start;
    }

    th {
      max-width: 80px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      z-index: 123123131313;
      @include breakpoint(md) {
        max-width: unset;
      }
    }

    .dark-theme & {
      background-color: #333335;
    }

    &.sticky-head {
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: #fff;

      .dark-theme & {
        background-color: #333335;
      }
    }
  }

  .itemDetail {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 180px;

    @include breakpoint(sm) {
      width: 200px;
    }
    @include breakpoint(md) {
      width: auto;
    }

    &__name {
      color: $defaultColor;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: none;
      white-space: nowrap;
      width: 102px;
      text-align: left;
      @include breakpoint(md) {
        width: auto;
      }

      .dark-theme & {
        color: $white;
      }
    }

    &__task {
      color: $blueGrayLight;
      text-align: flex-start;
      transition: none;

      span {
        transition: none;
      }

      .mobile {
        text-decoration: none;
        display: inline-block;
        @include breakpoint(md) {
          display: none;
        }
      }

      .desktop {
        text-decoration: none;
        display: none;
        @include breakpoint(md) {
          display: inline-block;
        }
      }
    }

    &__icon {
      align-items: center;
      border: 1px solid $blueLight;
      border-radius: 10px;
      display: flex;
      height: 35px;
      justify-content: center;
      width: 35px;
    }

    &-group {
      &:hover {
        position: relative;
        z-index: 1;

        .itemDetail-group-inner {
          display: block;
        }

        .itemDetail__icon {
          position: relative;
          z-index: 2;
        }
      }

      &-inner {
        position: absolute;
        display: none;
        background: #283149;
        border-radius: 15px;
        padding: 80px 25px 15px;
        top: -19px;
        left: -30px;
        z-index: 1;

        &__item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;

          div {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #fff;
            margin-left: 13px;
          }
        }
      }
    }
  }

  tbody {
    tr:last-child {
      td:nth-child(2) {
        border-bottom-left-radius: 8px;
      }

      td:nth-child(5) {
        border-bottom-right-radius: 8px;
      }
    }

    tr:hover {
      .itemDetail__icon {
        cursor: pointer;
        border-color: $white;

        path {
          fill: $white;
        }
      }
    }
  }
}

.support-tabs-wrapper {
  display: flex;
}

.graf {
  padding-top: 46px;
  overflow-x: auto;
  overflow-y: hidden;

  &__title {
    font-family: Noto_Sans, sans-serif;
    color: #283149;

    .dark-theme & {
      color: $white;
    }
  }

  .recharts-wrapper {
    overflow: hidden;
  }
}

.margin {
  margin-top: 24px;
  margin-bottom: 55px;
  @include breakpoint(sm) {
    margin-top: 14px;
  }
}

.support-select-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  @include breakpoint(sm) {
    flex-direction: row;
    align-items: center;
  }

  button {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    color: $black;
    border: 0;
    background: #fff0;
    cursor: pointer;
    border-radius: 10px;

    @include breakpoint(sm) {
      margin-left: auto;
    }

    .time-clock-wrapper {
      margin-left: 0.5ex;
    }

    span .active {
      color: $blue;
      text-decoration: underline;
    }

    .dark-theme & {
      color: $white;
    }
  }
}
