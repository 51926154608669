.post {
  margin-bottom: 49px;
  @include breakpoint(sm) {
    margin-bottom: 40px;
    padding-top: 11px;
  }
  @include breakpoint(md) {
    margin-bottom: 82px;
  }
  @include breakpoint(lg) {
    margin-bottom: 83px;
  }

  &__title {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      padding: 0;
    }
  }

  &__container {
    background: $white;
    border: 1px solid $light-2;
    border-radius: 10px;
    padding: 29px 17px 25px;
    @include breakpoint(sm) {
      padding: 30px 37px 33px;
    }
    @include breakpoint(md) {
      padding: 30px 37px 40px;
    }
    @include breakpoint(lg) {
      padding: 28px 37px 18px;
    }

    .dark-theme & {
      background: $dark-2;
      border: 1px solid $dark-2;
    }
  }
}
