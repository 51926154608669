$defaultColor: #222839;
$black: #000;
$dark: #222226;
$dark-2: #333335;
$dark-3: #222226;
$dark-4: #27272b;
$darkMedium: #1c1c1c;
$grayDark: #b6c2e2;

$white: #fff;
$light: #f8fafc;
$light-2: #eef3f9;
$darkWhite: #f6f6f6;

$orange: #facb73;
$orangeLight: #ffe0a6;

$yellow: #ffe854;

$red: #f00;

$green: #008000;
$greenLight: #d0ffbf;
$greenNeon: #b2ff8d;

$blue: #3262e0;
$blueLight: #dbe9f7;
$blueGray: #333335;
$blueGrayLight: #8592b6;
$blueNeon: #8ac8ff;

$gray: #627094;
$grayHover: #0033fe;
$darkgrayHover: #8eadff;

$headerColor: #c7c7df;
$headerColorWhite: #e7e7f4;
$titleColor: #283149;
$captionColor: #5a6788;
