@import "authotization/index";
@import "mykritlabMenu/index";
@import "mykritlabWiki/index";
@import "mykritlabArticles/index";
@import "mykritlabDetail/index";
@import "mykritlabSearch/index";
@import "accesses/index";
@import "accessesDetail/index";
@import "tasks/index";
@import "support/index";
@import "factLoadCosts/index";
@import "../_dash_loader.css";
