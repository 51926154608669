.factLoadCosts {
  margin-top: 35px;

  @include breakpoint(sm) {
    margin-top: 55px;
  }

  &__title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;

    @include breakpoint(sm) {
      font-size: 22px;
      line-height: 30px;
    }

    .dark-theme & {
      color: $white;
    }
  }

  &__nav {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid $blueLight;

    @include breakpoint(sm) {
      margin-top: 50px;
      padding-bottom: 22px;
    }

    .df-wrapper {
      margin-bottom: 25px;

      @include breakpoint(sm) {
        margin-bottom: 0;
      }

      .react-datepicker__input-container input {
        cursor: pointer;
        caret-color: transparent;
      }
    }

    &-wrapper {
      position: relative;
      max-height: 250px;
      overflow-y: auto;
      padding: 25px 10px 10px 15px;
    }

    &-tabs {
      position: relative;
      border-bottom: 1px solid #dbe9f7;

      .factLoadCosts__nav-wrapper {
        @include breakpoint(md) {
          columns: 4;
          max-height: unset;
        }

        @include breakpoint(lg) {
          columns: 5;
        }
      }

      &.-show {
        display: flex;
        flex-direction: column;
      }
    }

    button {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      line-height: 26px;
      color: $black;
      border: 0;
      background: #fff0;
      cursor: pointer;
      border-radius: 10px;

      @include breakpoint(sm) {
        margin-left: auto;
      }

      .time-clock-wrapper {
        margin-left: 0.5ex;
      }

      span .active {
        color: $blue;
        text-decoration: underline;
      }

      .dark-theme & {
        color: $white;
      }
    }

    &-mobileBtn {
      display: inline-block;
      margin-left: 6px;
      width: 40px;
      height: 40px;

      @include breakpoint(sm) {
        margin-left: 10px;
      }

      @include breakpoint(md) {
        display: none;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      &.-show {
        rect {
          fill: $blue;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &__content {
    &-allHours {
      font-size: 12px;
      line-height: 26px;
      color: $gray;
      padding: 8px 15px;
      background: $white;
      border-radius: 10px;
      margin-top: 48px;
      margin-bottom: 24px;
      max-width: max-content;

      .dark-theme & {
        color: $white;
        background-color: $blueGray;
      }
    }

    & .empty {
      color: $gray;
      margin-top: 50px;
      padding-bottom: 22px;

      .dark-theme & {
        color: $white;
      }
    }
  }

  &-tab {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 4px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $blue;
    border: 1px solid transparent;
    margin-bottom: 15px;
    background: transparent;
    cursor: pointer;

    .dark-theme & {
      color: $darkgrayHover;
    }

    &::after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      order: -1;
      background-color: #3eda00;
      margin-right: 10px;
    }

    &__close {
      display: none;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      right: 12px;
      top: 5px;

      svg {
        width: 10px;
        height: 10px;
        margin-left: auto;
      }
    }

    &.active {
      border-radius: 20px;
      background: $blueLight;
      border: 1px solid $blueLight;

      .dark-theme & {
        background-color: $blueGray;
      }

      .factLoadCosts-tab__close {
        display: block;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 30px;

    @include breakpoint(md) {
      display: none;
    }
  }

  &-reset {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    position: relative;
    background-color: $blue;
    border-radius: 50px;

    &.disabled {
      background-color: $gray;
      pointer-events: none;

      svg path {
        fill: #8592b6;
      }
    }
  }

  &-accept {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    color: $white;
    border-radius: 10px;
    font-size: 12px;
    padding: 9px 20px;
    min-width: 202px;
  }

  &__cardsWrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    position: relative;
    background: $white;
    border: 1px solid $blueLight;
    border-radius: 10px;
    font-size: 12px;
    line-height: 21px;
    color: #627094;
    padding: 27px 23px;
    margin-bottom: 10px;

    @include breakpoint(sm) {
      width: 44%;
      margin-right: 10px;
    }

    @include breakpoint(md) {
      width: 48%;
      margin-right: 15px;
      margin-bottom: 15px;
    }

    @include breakpoint(lg) {
      width: 31%;
      margin-right: 25px;
      margin-bottom: 25px;
    }

    .dark-theme & {
      background-color: $blueGray;
    }

    &-title {
      font-size: 15px;
      line-height: 24px;
      color: $titleColor;
      margin-bottom: 12px;

      .dark-theme & {
        color: $white;
      }
    }

    &-tasks {
      flex-grow: 1;
    }

    &-task {
      position: relative;
      padding-left: 10px;

      .dark-theme & {
        color: $white;
      }

      a {
        text-decoration: none;
        color: $blue;
      }

      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        display: block;
        width: 4px;
        height: 4px;
        background-color: #3eda00;
        border-radius: 50%;
        order: -1;
        margin-right: 5px;
      }
    }

    &-all {
      margin-top: 15px;

      .dark-theme & {
        color: $white;
      }
    }

    &-remove {
      position: absolute;
      top: 13px;
      right: 14px;
      cursor: pointer;
    }
  }
}
