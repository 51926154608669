.support-info {
  background: $white;
  border: 1px solid $light-2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 33px 21px 27px;
  position: relative;
  margin: 0 10px;
  @include breakpoint(sm) {
    margin: 0;
  }
  @include breakpoint(lg) {
    padding: 42px 21px;
  }

  .dark-theme & {
    background-color: $dark-2;
    border-color: $dark;
  }

  &--title {
    margin-top: 46px;
    @include breakpoint(sm) {
      margin-top: 52px;
    }
  }

  &__title {
    color: $titleColor;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding-left: 70px;
    @include breakpoint(sm) {
      padding-left: 100px;
    }
    @include breakpoint(md) {
      padding-left: 110px;
    }

    .dark-theme & {
      color: $white;
    }
  }

  &__text {
    color: $blueGrayLight;
    font-size: 14px;
    line-height: 21px;
    margin-top: 27px;
    @include breakpoint(sm) {
      margin-top: 7px;
      padding-left: 100px;
    }
    @include breakpoint(md) {
      padding-left: 110px;
    }

    .dark-theme & {
      color: $blueGrayLight;
    }
  }

  &__icon {
    height: 45px;
    position: absolute;
    top: 32px;
    width: 58px;
    @include breakpoint(sm) {
      height: 66px;
      left: 21px;
      top: 42px;
      width: 82px;
    }
    @include breakpoint(md) {
      left: 30px;
    }

    svg {
      width: 49px;
      height: 40px;
      @include breakpoint(sm) {
        width: auto;
        height: auto;
      }
    }
  }
}
