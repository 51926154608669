.wikiCategory {
  padding: 0 10px;
  @include breakpoint(sm) {
    padding: 19px 0 0;
  }
  @include breakpoint(md) {
    padding: 0;
  }

  &List {
    border: 1px solid $blueLight;
    border-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 17px 10px 18px;
    transition: all 0.5s ease;
    width: calc(100% + 20px);
    @include breakpoint(sm) {
      margin: 0;

      width: 100%;
    }
    @include breakpoint(md) {
      &:last-of-type {
      }
    }
    @include breakpoint(lg) {
      &:last-of-type {
      }
    }

    &:first-of-type {
      border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
      border: 1px solid $blueLight;
      border-radius: 0 0 10px 10px;
    }

    .dark-theme & {
      border-color: $dark-2;
    }
  }

  &Item {
    margin-bottom: 5px;
    transition: all 0.5s ease;
    width: 100%;
    @include breakpoint(sm) {
      margin-bottom: 10px;
      padding: 0 5px;
      width: 33.333%;

      &--highlighted {
        width: 40%;
      }

      &:last-of-type {
      }

      &--bordered {
        margin: 0 0 10px;
        width: 33.333%;
      }
    }
    @include breakpoint(md) {
      width: 25%;

      &--highlighted {
        width: 28.5%;
      }

      &--bordered {
        width: 25%;
      }
    }
    @include breakpoint(lg) {
      width: 20%;

      &--highlighted {
        width: 21%;
      }

      &--bordered {
        width: 20%;
      }
    }
    @include breakpoint(xl) {
      &--highlighted {
        width: 20%;
      }
    }

    &__name {
      color: $titleColor;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      transition: all 0.5s ease;
      @include breakpoint(sm) {
        font-size: 16px;
      }

      .dark-theme & {
        color: $darkgrayHover;
      }

      .dark-theme .wikiCategoryItem--highlighted & {
        color: $white;
      }
    }

    &__count {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 6px;
      transition: all 0.5s ease;

      .dark-theme & {
        color: #b6c2e3;
      }

      .dark-theme .wikiCategoryItem--highlighted & {
        color: $white;
      }
    }
  }
}

.wikiCategoryItem__link {
  background: $white;
  border: 1px solid $blueLight;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 11px 12px;
  text-decoration: none;
  transition: all 0.5s ease;
  @include breakpoint(sm) {
    height: 110px;
    padding: 17px 19px 23px;

    .wikiCategoryItem--bordered & {
      padding: 17px 19px 23px;
    }
  }
  @include breakpoint(md) {
    &:hover {
      background: $darkgrayHover;
      border: 1px solid $darkgrayHover;

      .wikiCategoryItem__name,
      .wikiCategoryItem__count {
        color: $white;
      }
    }

    .wikiCategoryItem--bordered &:hover {
      background: $orange;
      border: 1px solid $orange;

      .wikiCategoryItem__name,
      .wikiCategoryItem__count {
        color: $white;
      }
    }

    .wikiCategoryItem--highlighted &:hover {
      background: $blue;
      border: 1px solid $blue;

      .wikiCategoryItem__name,
      .wikiCategoryItem__count {
        color: $white;
      }
    }

    .dark-theme .wikiCategoryItem--highlighted &:hover {
      background: #5584ff;
      border: 1px solid #5584ff;
    }

    .dark-theme .wikiCategoryItem--bordered &:hover {
      background: rgba(250, 203, 115, 0.5);
      border: 1px solid transparent;
    }
  }

  .wikiCategoryItem--highlighted & {
    background: $blueLight;
  }

  .wikiCategoryItem--bordered & {
    border: 1px solid $orange;
    height: 100%;
    padding: 13px 11px;
    @include breakpoint(sm) {
      height: 110px;
      padding: 17px 19px 23px;
    }
  }

  .dark-theme & {
    background: $dark-2;
    border: 1px solid $dark-2;
  }

  .dark-theme .wikiCategoryItem--highlighted & {
    background: $blue;
    border: 1px solid $blue;
  }

  .dark-theme .wikiCategoryItem--bordered & {
    border: 1px solid rgba(250, 203, 115, 0.5);
  }
}
