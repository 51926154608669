.tasksContent {
  .tasksUsers__list {
    @include breakpoint(md) {
      padding: 37px 43px;
    }
    @include breakpoint(xl) {
      padding: 37px 50px 24px 43px;
    }

    a {
      text-decoration: none;
      color: #3262e0;
      transition: all 0.5s ease;
      font-weight: 600;

      &:hover {
        color: #222226;
      }

      .dark-theme & {
        &:hover {
          color: $white;
        }
      }
    }
  }
}
