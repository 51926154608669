.tasks {
  &Content {
    padding-top: 4px;
    @include breakpoint(sm) {
      padding-top: 23px;
    }

    &__title {
      padding-left: 10px;
      @include breakpoint(sm) {
        padding-left: 0;
      }

      br {
        display: none;
      }
    }
  }

  &Data {
    &__item {
      color: $gray;
      display: none;
      font-size: 11px;
      font-weight: 500;
      line-height: 21px;
      width: 100%;
      @include breakpoint(sm) {
        font-size: 12px;
      }
      @include breakpoint(md) {
        display: flex;
      }

      &--mobile {
        display: flex;
      }

      &Caption {
        display: none;
        @include breakpoint(md) {
          display: inline;
          margin-right: 3px;

          &--mobile {
            display: none;
          }
        }

        &--mobile {
          display: inline;
          margin-right: 3px;
        }
      }
    }
  }

  &Status {
    flex-shrink: 0;
    white-space: nowrap;

    &__icon {
      align-items: center;
      background-color: $light;
      border-radius: 8px;
      color: #a6c2de;
      display: flex;
      font-size: 11px;
      font-weight: 500;
      height: 43px;
      justify-content: center;
      letter-spacing: 0.5px;
      line-height: 21px;
      min-width: 120px;
      position: relative;
      @include breakpoint(sm) {
        border-radius: 10px;
        min-width: 111px;
        padding: 0 17px;

        .tasksItems__item--active & {
          min-width: 133px;
        }

        &--control {
          letter-spacing: 0.5px;
          min-width: 133px;
        }
      }
      @include breakpoint(xl) {
        min-width: 118px;

        .tasksItems__item--active & {
          min-width: 142px;
        }

        &--control {
          min-width: 142px;
        }
      }

      &--postponed::before {
        background: $orangeLight;
        border-radius: 0 5px 5px 0;
        content: "";
        display: block;
        height: 21px;
        left: 0;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 4px;
      }

      &--control::before {
        background: $blueNeon;
        border-radius: 0 5px 5px 0;
        content: "";
        display: block;
        height: 21px;
        left: 0;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 4px;
      }

      &--ready::before {
        background: $greenNeon;
        border-radius: 0 5px 5px 0;
        content: "";
        display: block;
        height: 21px;
        left: 0;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 4px;
      }

      .dark-theme & {
        background-color: $dark;
        color: #a0aac6;
      }

      .tasksItems__item--active & {
        background-color: $darkgrayHover;
        color: $white;
      }
    }
  }
}
