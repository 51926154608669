.styledSelect {
  width: 270px;
  @include breakpoint(sm) {
    width: 270px;
  }

  .styledSelect {
    &__control {
      background-color: $white;
      border: 1px solid $blueLight;
      border-radius: 2px;
      box-shadow: none;
      cursor: pointer;
      min-height: 32px;
      @include breakpoint(sm) {
        border-radius: 7px;
        min-height: 52px;

        &--menu-is-open {
          border-radius: 7px 7px 0 0;
        }
      }

      &:hover {
        border: 1px solid $blueLight;
      }

      &--menu-is-open {
        border-radius: 2px 2px 0 0;
      }

      .dark-theme & {
        background-color: $dark;
        border: 1px solid $darkgrayHover;

        &:hover {
          border: 1px solid $darkgrayHover;
        }
      }

      .editAccess &,
      .addNewAccess & {
        border-radius: 7px;
        min-height: 52px;

        .styledSelect__value-container {
          padding-left: 19px;

          .styledSelect__single-value {
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 16px;
          }
        }
      }
    }

    &__value-container {
      padding: 0 0 0 10px;
      width: calc(100% - 25px);
      @include breakpoint(sm) {
        padding: 0 0 0 25px;
        width: calc(100% - 35px);
      }
    }

    &__single-value {
      color: $blue;
      font-family: $default;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      margin: 0;
      max-width: 100%;
      transition: all 0.3s;
      width: 100%;
      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 18px;
      }

      .dark-theme & {
        color: $darkgrayHover;
      }
    }

    &__indicators {
      flex-shrink: 0;
      transition: all 0.3s;
      @include breakpoint(sm) {
        svg {
          height: 22px;
          width: 22px;
        }
      }

      svg {
        fill: $blue;
        height: 16px;
        transition: transform 0.3s ease;
        width: 16px;

        .styledSelect__control--menu-is-open & {
          transform: rotateX(180deg);
        }

        .dark-theme & {
          fill: $darkgrayHover;
        }
      }
    }

    &__indicator {
      padding: 7px;
      @include breakpoint(sm) {
        padding: 5px 19px 5px 5px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      background-color: $white;
      border: 1px solid $blueLight;
      border-radius: 0 0 5px 5px;
      border-top: 0;
      box-shadow: none;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      margin: -1px 0 0;
      transition: all 0.3s;
      z-index: 102;
      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 19px;
        margin: -2px 0 0;
      }

      .dark-theme & {
        background-color: $dark !important;
        border-color: $darkgrayHover;
      }

      &-list {
        border-radius: 0;
      }
    }

    &__option {
      background-color: $white !important;
      color: $gray;
      cursor: pointer;
      font-family: $default;
      padding: 6px 11px;
      @include breakpoint(sm) {
        padding: 8px 25px;
      }

      &--is-focused {
        color: $gray;
      }

      &--is-selected {
        color: $blue;
      }

      .dark-theme & {
        background-color: $dark !important;

        &--is-selected {
          color: $darkgrayHover;
        }
      }
    }
  }

  .styledSelect__menu-list {
    &::-webkit-scrollbar {
      width: 5px;
      color: #fff;
    }

    &::-webkit-scrollbar-track {
      background-color: #283149;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3262e0;
      border-radius: 20px;
    }
  }
}
