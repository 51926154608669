.messInformer {
  align-items: flex-end;
  background: $white;
  border-radius: 10px;
  bottom: 91px;
  max-width: 300px;
  position: absolute;
  right: 11px;
  z-index: 1;
  display: none;
  @include breakpoint(sm) {
    bottom: 58px;
    max-width: 330px;
    right: 41px;
  }
  @include breakpoint(md) {
    bottom: 106px;
  }

  .dark-theme & {
    background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
  }

  &__inner {
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.5s ease;
    @include breakpoint(sm) {
      &.-showed {
        width: 330px;
      }
    }

    &.-showed {
      width: 300px;
    }

    .dark-theme & {
      background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
    }
  }

  &__item {
    background: $white;
    border: 1px solid transparent;
    border-radius: 10px;
    bottom: -1px;
    box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
    display: flex;
    opacity: 0;
    padding: 21px 50px 19px 17px;
    position: absolute;
    right: -1px;
    transition: all 0.3s ease;
    visibility: hidden;
    width: 300px;
    @include breakpoint(sm) {
      padding: 21px 50px 19px 27px;
      width: 330px;
    }

    .-showed &:first-of-type {
      animation: zoom 0.5s ease-in-out;
      border: 1px solid $grayDark;
      opacity: 1;
      visibility: visible;
    }

    .dark-theme & {
      background: $dark-2;
      border: 1px solid $dark-2 !important;
      box-shadow: 5px 5px 10px $darkMedium;
    }
  }

  &__close {
    margin-top: 2px;

    &Btn {
      background: transparent;
      border: 0;
      box-shadow: none;
      cursor: pointer;
      display: inline-block;
      line-height: 18px;
      margin: 0;
      padding: 0;
      vertical-align: top;

      svg {
        fill: $grayDark;
      }

      .dark-theme & {
        svg {
          fill: #555;
        }
      }
    }
  }

  &__text {
    color: $gray;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;

    a {
      color: #0086ff;
      text-decoration: none;
    }

    .dark-theme & {
      color: #b6c2e3;
    }
  }

  &__button {
    align-items: center;
    background: $white;
    border: 0;
    border-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 50px;
    z-index: 1;

    .-active &::before {
      background-color: $blue;
      border-radius: 50%;
      content: "";
      display: block;
      height: 5px;
      position: absolute;
      right: 16px;
      top: 17px;
      width: 5px;
    }

    .dark-theme & {
      background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
    }
  }

  &__icon {
    display: block;
    flex-shrink: 0;
    height: 21px;
    margin-bottom: 1px;
    width: 19px;

    svg {
      fill: $grayDark;
    }

    .dark-theme & svg {
      fill: #8592b6;
    }
  }
}
