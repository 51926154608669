.oftenUsed__tableRow:nth-child(2) .burger__content {
  border-radius: 0 0 15px 15px;
}

.burger {
  margin-right: 17px;
  &__content {
    background: $blue;
    border-radius: 15px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 100%;
    flex-shrink: 0;
    left: unset;
    right: -20px;
    top: -10px;
    opacity: 0;
    pointer-events: none;
    padding: 68px 20px 25px 26px;
    @include breakpoint(sm) {
      top: 0;
      left: 0;
      padding: 68px 20px 30px;
    }

    @include breakpoint(md) {
      padding-left: 20px;
      padding-top: 35px;
      padding-bottom: 35px;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      user-select: none;
      text-decoration: none;

      svg {
        margin-right: 11px;
      }

      &--delete {
        margin-top: 34px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      button.editIcon {
        &:hover {
          path {
            fill: $white;
          }
        }
      }
    }

    .nopointer {
      cursor: default;
    }

    &-text {
      position: relative;
      color: $white;

      &::after {
        content: attr(data-value);
        width: 100%;
        left: 0;
        font-size: 13px;
        @include breakpoint(sm) {
          font-size: 14px;
        }
      }
    }
  }

  &:hover {
    .burger__content {
      display: flex;
      opacity: 1;
      z-index: 1;
      pointer-events: all;
    }

    .burger-head {
      position: relative;
      z-index: 2;
      display: none;

      rect {
        fill: $white;
      }
    }

    & + .accessDetail__itemInner,
    & + .oftenUsed__tableColInner {
      z-index: 0;
    }

    & + .accessDetail__itemInner {
      @include breakpoint(sm) {
        position: relative;
        z-index: 0;
        color: $white;
      }
    }
  }

  .dark-theme & {
    &__icon {
      rect {
        fill: $darkgrayHover;
      }
    }

    &__content {
      background-color: $darkgrayHover;
    }
  }
}
.oftenUsed__host {
  word-break: break-word;
}
