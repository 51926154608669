.switcher {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 11px 8px 14px 0;
  @include breakpoint(sm) {
    margin-left: -19px;
    padding: 0;
  }

  &Button {
    background-color: transparent;
    border: 0;
    color: $titleColor;
    cursor: pointer;
    display: inline-block;
    font-family: $secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 17px;
    padding: 0;
    text-decoration: none;
    transition: color 0.5s ease;
    vertical-align: top;
    @include breakpoint(sm) {
      margin: 0 15px 0 18px;
    }

    .dark-theme & {
      color: $white;
    }
  }

  &Check {
    background: $white;
    border: 1px solid #8592b6;
    border-radius: 20px;
    box-shadow: none;
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    height: 24px;
    position: relative;
    transition: all 0.5s ease;
    width: 42px;
    @include breakpoint(sm) {
      margin-top: 1px;
    }

    .dark-theme & {
      background: linear-gradient(90deg, $dark-2 0%, $dark-2 100%);
      border: 1px solid transparent;
      box-shadow: 5px 5px 10px $darkMedium;
    }
  }

  &Input {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    z-index: -1;

    &:checked + .switcherToggle {
      left: calc(100% - 24px);
    }
  }

  &Toggle {
    background: #8592b6;
    border-radius: 50%;
    display: block;
    height: 14px;
    left: 0;
    margin: 4px;
    position: absolute;
    top: 0;
    transition: all 0.3s ease;
    width: 14px;
  }
}
