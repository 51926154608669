.post__content {
  padding-bottom: 10px;

  h2,
  h3,
  h4 {
    color: #5a6788;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;

    @include breakpoint(sm) {
      font-size: 20px;
      line-height: 27px;
    }

    .dark-theme & {
      color: $white;
    }
  }

  h3 {
    font-size: 18px;
    margin: 40px 0 10px;
  }

  h4 {
    margin: 40px 0 10px;
  }

  h2 {
    font-size: 24px;
    margin: 40px 0 20px;
    color: #283149;
  }

  ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;

    li {
      position: relative;
      padding-left: 35px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 18px;
        top: 11px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: rgb(124, 137, 171);
      }
    }
  }

  &Item {
    padding-bottom: 15px;
    padding-top: 24px;

    @include breakpoint(sm) {
      padding-top: 29px;
    }

    @include breakpoint(md) {
      padding-bottom: 13px;
      padding-top: 33px;
    }

    @include breakpoint(lg) {
      padding-bottom: 8px;
      padding-top: 37px;
    }
  }

  &Text {
    color: $defaultColor;
    font-size: 14px;
    line-height: 21px;
    overflow-x: auto;

    @include breakpoint(sm) {
      img {
        margin-top: 28px;
      }
    }

    @include breakpoint(md) {
      font-size: 16px;
      line-height: 26px;

      img {
        margin-bottom: 15px;
        margin-top: 32px;
      }
    }

    @include breakpoint(xl) {
      img {
        margin-top: 34px;
      }
    }

    .dark-theme & {
      color: $darkWhite;
    }

    p {
      margin-bottom: 14px;
    }

    a {
      color: $blue;
      text-decoration: none;
      transition: all 0.5s ease;

      .dark-theme &:hover {
        color: $white;
      }

      .dark-theme & {
        color: $darkgrayHover;
      }
    }

    /*multilevel lists*/
    > ol {
      list-style: none;
      counter-reset: li;
    }

    > ol > li {
      position: relative;
      list-style: none;
    }

    > ol > li::before {
      margin-right: 10px;
      content: counter(li) ".";
      counter-increment: li;
    }

    //========================
    img {
      margin-bottom: 14px;
      margin-top: 24px;
    }

    table {
      border: 1px solid $blueLight;
      border-radius: 10px;
      table-layout: auto;
      width: 100%;
      border-spacing: 15px;

      @include breakpoint(md) {
        table-layout: fixed;
      }

      & tr {
        padding: 10px 0;

        & td {
          padding: 0 10px;
          text-align: left;
        }

        & td:nth-child(1) {
          width: 15%;
        }

        & td:nth-child(2) {
          width: 25%;
        }

        & td:nth-child(3) {
          width: 15%;
        }

        & td:nth-child(4) {
          width: 10%;
        }

        & td:nth-child(5) {
          width: 35%;
        }
      }
    }
  }

  &__code {
    position: relative;
    margin-bottom: 26px;
    margin-top: 14px;

    @include breakpoint(sm) {
      margin-bottom: 25px;
      margin-top: 15px;
    }

    @include breakpoint(md) {
      margin-bottom: 24px;
      margin-top: 11px;
    }

    button {
      position: absolute;
      color: $gray;
      top: 5px;
      right: 10px;
      padding: 4px;
      font-size: 11px;
      border-radius: 10px;
      background: #e9f1f9;
      cursor: pointer;
      border: 0;
      box-shadow: $darkgrayHover 0 0 0;
      transition: all 0.3s ease;
      z-index: 10;

      &:hover {
        color: #283149;
        box-shadow: $darkgrayHover 0 0 5px;
      }

      .dark-theme &:hover {
        color: #283149;
        box-shadow: $blueGrayLight 0 0 5px;
      }

      &:active {
        box-shadow: inset 0 0 5px 0 !important;
      }

      &::after {
        content: "copy";
      }
    }
  }

  pre {
    position: relative;
  }

  pre code {
    position: static;
  }

  code {
    background: $light;
    color: $blueGrayLight;
    display: block;
    font-size: 14px;
    font-family: $default;
    line-height: 21px;
    padding: 20px 15px;
    position: relative;
    transition: all 0.5s ease;
    overflow-x: scroll;

    @include breakpoint(sm) {
      padding: 27px 18px 29px;
    }

    @include breakpoint(md) {
      font-size: 16px;
      line-height: 22px;
      padding: 27px 33px 28px;
    }

    .dark-theme & {
      background: $dark;
      color: #a0aac6;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .ps--active-x {
    code {
      padding: 0 55px 0 0;

      @include breakpoint(sm) {
        padding: 0 65px 0 0;
      }
    }
  }

  .ps__rail-x {
    height: 2px;
    opacity: 1;
  }

  .ps__thumb-x {
    background-color: #bcdaf9;
    bottom: 0;
    height: 2px;

    .dark-theme & {
      background-color: #a0aac6;
    }
  }

  .ps:hover > .ps__rail-x,
  .ps--focus > .ps__rail-x,
  .ps--scrolling-x > .ps__rail-x {
    opacity: 1;
  }

  .ps--active-x > .ps__rail-x {
    background-color: #e5f1fd;

    .dark-theme & {
      background-color: $black;
    }
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #bcdaf9;
    height: 2px;

    .dark-theme & {
      background-color: #a0aac6;
    }
  }
}

.post__contentCodeCopy {
  align-items: center;
  background: $blueLight;
  border: 0;
  border-radius: 10px;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 10px;
  transition: all 0.5s ease;
  width: 40px;

  @include breakpoint(sm) {
    bottom: 14px;
    height: 50px;
    right: 15px;
    width: 50px;

    svg {
      height: 19px;
      width: 19px;
    }
  }

  .dark-theme & {
    background: $dark-2;
    box-shadow: 5px 5px 10px $darkMedium;
  }

  svg {
    fill: $blueGrayLight;
    height: 16px;
    transition: all 0.5s ease;
    width: 16px;
  }

  &:hover {
    background: $darkgrayHover;

    svg {
      fill: $white;
    }
  }
}
