.loader-container {
  width: 100%;
  height: 100%;
}

.dark {
  background-color: #222226;
}

.loader-container__inner {
  position: absolute;
  top: 50%;
  left: 48%;
}

@media screen and (max-width: 880px) {
  .loader-container__inner {
    position: absolute;
    top: 50%;
    left: 47%;
  }
}

@media screen and (max-width: 550px) {
  .loader-container__inner {
    position: absolute;
    top: 50%;
    left: 46%;
  }
}

@media screen and (max-width: 420px) {
  .loader-container__inner {
    position: absolute;
    top: 50%;
    left: 45%;
  }
}
