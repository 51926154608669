.accessDetail {
  padding: 0 0 55px;

  @include breakpoint(sm) {
    padding: 20px 0 71px;
  }

  @include breakpoint(md) {
    padding: 12px 0 88px;
  }

  .checkbox {
    margin-bottom: 25px;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  &__top {
    max-width: 1280px;
    position: relative;
    z-index: 2;

    &Row {
      margin-bottom: 20px;

      @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        padding-top: 11px;
      }

      @include breakpoint(md) {
        align-items: flex-start;
      }

      &--flex {
        display: flex;
        align-items: flex-start;
      }

      &:last-child {
        margin-bottom: 0;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__bottom {
    margin-top: 31px;

    @include breakpoint(sm) {
      margin-top: 13px;
    }

    @include breakpoint(md) {
      margin-top: 14px;
    }
  }

  &__card {
    border-radius: 10px;
    margin: 0 -10px;
    width: 100%;
    padding: 36px 20px 41px;
    position: relative;
    z-index: 2;

    @include breakpoint(sm) {
      margin: 0 -1px;
      padding: 37px 29px 41px;
      width: 100%;
      max-width: unset;
      border: 1px solid $blueLight;
    }

    @include breakpoint(md) {
      display: block;
      width: 100%;
      margin: 0;
    }

    &Row {
      display: flex;
      margin-bottom: 16px;

      @include breakpoint(md) {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &Caption {
      color: $defaultColor;
      font-size: 14px;
      line-height: 26px;
      padding: 2px 8px 2px 0;

      .dark-theme & {
        color: $white;
      }
    }

    &Value {
      background-color: $white;
      border-radius: 5px;
      color: $defaultColor;
      font-size: 14px;
      line-height: 26px;
      padding: 2px 8px 2px 9px;

      a {
        color: $blue;
        font-weight: 500;
        text-decoration: none;

        .dark-theme & {
          color: $defaultColor;
        }
      }
    }
  }

  &__add {
    text-decoration: none;
    margin-bottom: 18px;
    border: 0 solid transparent;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }

    &Link {
      align-items: center;
      background-color: $white;
      border: 1px solid $light-2;
      border-radius: 7px;
      box-shadow: none;
      color: $titleColor;
      cursor: pointer;
      display: flex;
      font-weight: 600;
      line-height: 18px;
      margin: 0;
      max-width: 300px;
      padding: 0;
      text-decoration: none;
      transition: all 0.3s ease;
      width: auto;
      font-size: 0;

      @include breakpoint(sm) {
        font-size: 13px;
        padding-right: 20px;
      }

      .dark-theme & {
        background-color: $dark-2;
        border: 1px solid $blueGray;
        color: $white;
      }

      span {
        align-items: center;
        background-color: $blue;
        border-radius: 7px;
        display: inline-flex;
        height: 50px;
        width: 50px;
        justify-content: center;
        margin-left: -1px;
        margin-right: 0;
        margin-top: -1px;
        transition: all 0.5s ease;

        @include breakpoint(sm) {
          margin-right: 20px;
        }

        .dark-theme & {
          background-color: $darkgrayHover;
        }

        .accessDetail__addLink:hover & {
          background-color: $darkgrayHover;
        }

        .dark-theme .accessDetail__addLink:hover {
          background-color: $blue;
        }
      }

      .text-small {
        @include breakpoint(md) {
          display: none;
        }
      }

      .text-big {
        display: none;

        @include breakpoint(md) {
          display: block;
        }
      }
    }

    &:first-child {
      margin-right: 10px;

      @include breakpoint(sm) {
        margin-right: 20px;
      }
    }
  }

  &__form {
    max-width: 548px;
    position: relative;
    width: 100%;
    margin-left: 10px;

    @include breakpoint(sm) {
      max-width: 223px;
      width: 100%;
      margin-left: auto;
    }

    @include breakpoint(md) {
      max-width: 350px;
    }

    @include breakpoint(lg) {
      max-width: 490px;
    }

    input {
      font-size: 14px;
    }

    &Wrap {
      display: flex;
    }
  }

  &__input {
    background-color: transparent;
    border: 1px solid $blueLight;
    border-radius: 7px;
    color: $titleColor;
    font-family: $secondary;
    letter-spacing: 0.3px;
    line-height: 16px;
    padding: 18px 50px 16px 0;
    transition: all 0.3s ease;
    width: 50px;
    height: 50px;
    font-size: 14px;
    margin-left: auto;

    @include breakpoint(sm) {
      width: 100%;
      padding: 18px 50px 16px 24px;
    }

    .dark-theme & {
      background-color: $dark;
      border: 1px solid $darkgrayHover;
      color: $white;
    }

    &::placeholder {
      color: $blueGrayLight;
      opacity: 1;
      transition: all 0.3s ease;

      .dark-theme & {
        color: #a0aac6;
      }
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &.-open {
      width: 100%;
      padding-left: 10px;
    }
  }

  &__button {
    align-items: center;
    background-color: $white;
    border: 0;
    border-radius: 7px;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 1px;
    top: 1px;
    transition: all 0.3s ease;
    width: 49px;

    svg {
      fill: $grayDark;
      flex-shrink: 0;
    }

    .dark-theme & {
      background-color: $dark;

      svg {
        fill: $darkgrayHover;
      }
    }
  }

  &__list {
    position: relative;
    z-index: 1;

    @include breakpoint(sm) {
      border-bottom: 1px solid $light-2;
      border-top: 1px solid $light-2;
      margin: 0 -40px;
      background-color: $white;
    }

    @include breakpoint(md) {
      border: 1px solid $light-2;
      border-radius: 10px;
      margin: 0;
      max-width: 1280px;
      overflow: visible;

      .dark-theme & {
        border: 1px solid $dark-2;
      }
    }

    .dark-theme & {
      background-color: $dark-2;
      border-bottom: 1px solid $dark-2;
      border-top: 1px solid $dark-2;
    }

    &Wrap {
      padding: 0 0 22px;

      @include breakpoint(sm) {
        min-width: 768px;
        padding: 24px 19px;
      }

      @include breakpoint(md) {
        padding: 23px 24px;
      }
    }
  }

  &__icon {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg {
      display: block;
    }
  }

  .copyIcon {
    svg {
      margin-right: 10px;
      fill: $grayDark;
    }
  }

  .likeIcon {
    &__filled {
      fill: $grayDark;
      transform: translate(0, 10px);
    }

    &__bordered {
      stroke: $grayDark;
      transform: translate(-4px, -9px);
    }

    &--liked {
      .likeIcon__filled {
        fill: #ffbfe6;
        transform: translate(0, 7px);
      }

      .likeIcon__bordered {
        stroke: #ffbfe6;
        transform: translate(0, -7px);
      }
    }
  }

  .editIcon {
    svg {
      fill: $grayDark;

      path {
        transition: all 0.5s ease;
      }
    }

    &:hover {
      path {
        fill: $blue;
      }
    }
  }

  .requestIcon {
    path {
      transition: all 0.5s ease;
    }

    &:hover {
      path {
        stroke: $blue;
      }
    }
  }

  .deleteIcon {
    svg {
      fill: $blueLight;
    }
  }
}

.hideText {
  &::after {
    content: attr(data-text);
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: $white;
    -webkit-text-security: disc;
    pointer-events: none;
    transition: none;

    .dark-theme & {
      background-color: $blueGray;
    }
  }
}

.hideHead {
  min-width: 30%;

  @include breakpoint(sm) {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  span {
    display: none;

    @include breakpoint(sm) {
      display: inline;
    }
  }
}
.empty {
  color: #627094;
  margin-top: 20px;
  padding: 20px 0 20px 0;
  @include breakpoint(sm) {
    padding: 20px 0 20px 18px;
  }
  .dark-theme & {
    color: $white;
  }
}
.accessEmpty {
  padding-left: 10px;
  @include breakpoint(md) {
    padding-left: 10px;
  }
  @include breakpoint(lg) {
    padding-left: 22px;
  }
}
