.wikiListBlock {
  padding: 0 10px;
  @include breakpoint(sm) {
    padding: 2px 0 0;
  }

  &__list {
    margin: 0 -20px;
    position: relative;
    @include breakpoint(sm) {
      margin: 0 -10px;
    }

    &Empty {
      background: $white;
      border: 1px solid $light-2;
      border-radius: 10px;
      box-sizing: border-box;
      color: #8592b6;
      font-size: 14px;
      line-height: 21px;
      margin: 0 20px 51px;
      @include breakpoint(sm) {
        margin: 0 10px 51px;
      }

      .dark-theme & {
        background-color: $dark-2;
        border: 1px solid $dark-2;
        box-shadow: 5px 5px 10px $darkMedium;
      }

      &Wrap {
        align-items: center;
        display: flex;
        padding: 28px 10px 32px;
        @include breakpoint(sm) {
          padding: 38px 40px 42px;
        }
      }

      &Icon {
        flex-shrink: 0;
        position: relative;
        width: 70px;
        @include breakpoint(sm) {
          width: 90px;
        }

        & > svg {
          position: relative;
          z-index: 1;
        }

        &--small {
          bottom: -5px;
          left: 24px;
          position: absolute;
          width: auto;
          z-index: 0;
        }
      }

      &Title {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin-bottom: 7px;
        padding-top: 3px;
      }
    }
  }

  &__slider {
    @include breakpoint(sm) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    @include breakpoint(md) {
      display: block;
    }
  }

  &__col {
    padding: 0 5px;
    @include breakpoint(sm) {
      padding: 0 10px;
      width: 50%;
    }
    @include breakpoint(md) {
      padding: 0;
      display: flex !important;
      flex-wrap: wrap;
      width: 100%;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .slick-slide &:first-of-type {
      padding-left: 5px;
    }

    .slick-slide:first-of-type & {
      padding-left: 10px;
    }

    .slick-slide &:last-of-type {
      padding-right: 5px;
    }

    .slick-slide:last-of-type & {
      padding-right: 10px;
    }

    &:first-of-type {
      padding-left: 10px;
    }

    &:last-of-type {
      padding-right: 10px;
    }
  }

  &Item {
    margin-bottom: 10px;
    min-width: 260px;
    position: relative;
    transition: all 0.5s ease;
    width: 93vw;
    @include breakpoint(sm) {
      margin-bottom: 20px;
      width: 90vw;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @include breakpoint(md) {
      margin-bottom: 20px;
      padding: 0 10px;
      width: 33.3333%;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    &.removed {
      margin: 0;
      transform: scale(0);
    }

    &__wrap {
      background: $white;
      border: 1px solid $light-2;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 140px;
      justify-content: space-between;
      padding: 26px 18px 22px;
      text-decoration: none;
      transition: all 0.5s ease;
      width: 100%;
      @include breakpoint(sm) {
        padding: 26px 22px 25px;
      }
      @include breakpoint(md) {
        height: 140px;
        padding: 26px 19px 25px;

        .wikiListBlockItem:hover & {
          border: 1px solid $white;
          border-radius: 15px;
          box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
        }

        .dark-theme .wikiListBlockItem:hover & {
          border: 1px solid $dark-2;
          box-shadow: 5px 5px 10px $darkMedium;
        }
      }
      @include breakpoint(lg) {
        padding: 25px 26px;
      }
      @include breakpoint(xl) {
        padding: 26px 29px 25px;
      }

      .dark-theme & {
        background: $dark-2;
        border: 1px solid $dark-2;
      }

      .removed & {
        border: 0;
        height: 0;
        padding: 0;
        transition-delay: 0.5s;
      }
    }

    &__link {
      box-orient: vertical;
      color: $blue;
      display: inline-block;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      -webkit-line-clamp: 3;
      line-height: 21px;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: all 0.5s ease;
      vertical-align: top;
      @include breakpoint(sm) {
        font-size: 16px;
        -webkit-line-clamp: 2;
        line-height: 24px;
      }
      @include breakpoint(md) {
        .wikiListBlockItem__wrap:hover & {
          color: $grayHover;
        }

        .dark-theme .wikiListBlockItem__wrap:hover & {
          color: $white;
        }
      }

      .dark-theme & {
        color: $darkgrayHover;
      }
    }

    &__dates {
      color: $gray;
      font-size: 11px;
      font-weight: 500;
      line-height: 15px;
      transition: all 0.5s ease;
      @include breakpoint(sm) {
        font-size: 12px;
        line-height: 16px;
      }
      @include breakpoint(md) {
        .dark-theme .wikiListBlockItem__wrap:hover & {
          color: $white;
        }
      }

      .dark-theme & {
        color: #b6c2e3;
      }
    }

    .favorite {
      background-color: transparent;
      border: 0;
      bottom: 23px;
      height: auto !important;
      position: absolute;
      right: 20px;
      @include breakpoint(sm) {
        bottom: 28px;
        right: 21px;
      }
      @include breakpoint(md) {
        bottom: 27px;
        right: 31px;
      }
      @include breakpoint(xl) {
        right: 29px;
      }

      &__text {
        display: none;
      }

      &__icon {
        height: 15px;
        width: 16px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .slick-slider .slick-dots {
    padding: 4px 14px;
    position: static;
  }
}

.wikiListBlockItem__date {
  display: inline-block;
  vertical-align: top;

  &--change {
    margin-left: 12px;
    @include breakpoint(sm) {
      margin-left: 31px;
    }
    @include breakpoint(md) {
      margin-left: 20px;
    }
    @include breakpoint(lg) {
      margin-left: 20px;
    }
    @include breakpoint(xl) {
      margin-left: 18px;
    }
  }

  &Caption {
    margin-right: 2px;
    @include breakpoint(sm) {
      margin-right: 3px;
    }

    &--short {
      @include breakpoint(lg) {
        display: none;
      }
    }

    &--full {
      display: none;
      @include breakpoint(lg) {
        display: inline;
      }
    }
  }
}

.mt30 {
  margin-top: 30px;
}

.center {
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.jcc {
  justify-content: center;
}

.fullWidth {
  width: 100%;
}
