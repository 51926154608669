@keyframes update-animation {
  0% {
    width: 0;
  }

  25% {
    width: 25%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

.update {
  align-items: center;
  background-color: #222839;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 25px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  &__title {
    color: #dbe9f7;
    display: block;
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
  }

  &__desc {
    color: #dbe9f7;
    margin-bottom: 25px;
  }

  &-bar {
    background-color: #dbe9f7;
    height: 10px;
    max-width: 520px;
    width: 100%;

    &__line {
      animation: update-animation 1.5s linear;
      animation-fill-mode: both;
      background-color: #333335;
      height: 100%;
      width: 0;
    }
  }
}
