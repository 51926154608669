.header {
  background-color: $titleColor;
  color: $headerColor;
  font-family: $secondary;
  transition: all 0.5s ease;

  .dark-theme & {
    background-color: $dark;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    background-color: #283149;
    justify-content: space-between;
    min-height: 64px;
    padding: 15px 10px 16px;

    @include breakpoint(sm) {
      min-height: 107px;
      padding: 15px 0 24px;

      &--logout {
        justify-content: flex-start;
      }
    }

    .dark-theme & {
      background-color: $dark;
    }

    &--logout {
      justify-content: center;
    }

    &--secondary {
      position: relative;

      .wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    color: #c7c7df;
    justify-content: space-between;
    @include breakpoint(sm) {
      padding: 0 60px;
    }
  }

  &__isAuth {
    justify-content: center;
    @include breakpoint(xs) {
      justify-content: space-between;
    }
  }

  &__logo {
    .header__wrapper--secondary & {
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }

    .header__wrapper--secondary .header__middle & {
      display: block;
      @include breakpoint(sm) {
        display: none;
      }
    }
  }

  &__link {
    display: inline-block;
    height: 33px;
    vertical-align: top;
    width: 64px;
    @include breakpoint(sm) {
      width: 86px;
    }

    svg {
      display: inline-block;
      vertical-align: top;
      width: 140px;
    }

    .header__wrapper--secondary .header__middle & {
      height: 33px;
      margin-bottom: 24px;
      margin-left: 23px;
      width: 92px;
    }
  }

  &__user {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    line-height: 14px;
    margin-right: 1px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__login {
    text-align: right;
    color: $headerColorWhite;
    text-decoration: none;
    cursor: pointer;
  }

  &__exit {
    margin-left: 12px;
    @include breakpoint(sm) {
      margin-left: 33px;

      .header__wrapper--secondary & {
        margin-left: 8px;
      }
    }
    @include breakpoint(md) {
      .header__wrapper--secondary & {
        margin-left: 33px;
      }
    }
    @include breakpoint(lg) {
      margin-left: 35px;
    }

    &Link {
      color: $headerColorWhite;
      display: inline-block;
      font-family: $secondary;
      text-decoration: none;
      vertical-align: top;
      cursor: pointer;
      @include breakpoint(sm) {
        align-items: center;
        display: flex;
      }
    }

    &Text {
      display: none;
      @include breakpoint(sm) {
        display: block;
        margin-right: 8px;

        .header__wrapper--secondary & {
          display: none;
        }
      }
      @include breakpoint(md) {
        .header__wrapper--secondary & {
          display: block;
        }
      }
    }

    &Icon {
      display: inline-block;
      flex-shrink: 0;
      height: 24px;
      vertical-align: top;
      width: 30px;

      svg {
        max-width: 100%;
        min-height: 100%;
      }
    }
  }

  &__icon {
    line-height: 19px;
    cursor: pointer;
    @include breakpoint(sm) {
      margin-right: 42px;
      margin-top: 5px;
      display: none;
    }

    &Btn {
      display: inline-block;
      margin-top: 1px;
      vertical-align: top;

      svg {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  &__left {
    position: relative;
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      width: 30%;
    }
    @include breakpoint(md) {
      width: 28%;
    }
    @include breakpoint(lg) {
      width: 22%;
    }
  }

  &__right {
    position: relative;
    @include breakpoint(sm) {
      flex-shrink: 0;
      width: 30%;
    }
    @include breakpoint(md) {
      width: 28%;
    }
    @include breakpoint(lg) {
      width: 22%;
    }
  }

  &__middle {
    background-color: $titleColor;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    min-width: 320px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: fixed;
    right: auto;
    top: 0;
    transform: translate3d(0, -110%, 0);
    transition: transform 0.3s ease;
    width: 100%;
    z-index: 500;
    @include breakpoint(sm) {
      background-color: transparent;
      min-width: inherit;
      overflow: visible;
      padding: 0 3px;
      position: static;
      transform: none;
      width: 40%;
      z-index: 502;

      .dark-theme & {
        background-color: transparent;
      }
    }
    @include breakpoint(md) {
      width: 44%;
    }
    @include breakpoint(lg) {
      padding: 0 8px;
      width: 56%;
    }
    @include breakpoint(xl) {
      padding: 0 11px;
      width: 56%;
    }

    .menu-is-open & {
      transform: none;
    }

    &Wrap {
      flex: 1;
      padding: 20px;
      position: relative;
      width: 100%;
      @include breakpoint(sm) {
        padding: 0;
      }
    }

    &Top {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      width: 100%;
      @include breakpoint(sm) {
        display: none;
      }
    }
  }

  &__close {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 69px;
    justify-content: center;
    opacity: 0;
    text-align: center;
    transition: all 0.3s ease;
    transition-delay: 0.3s;
    width: 100%;
    @include breakpoint(sm) {
      bottom: 0;
      left: 0;
      position: absolute;

      .header__middle & {
        display: none;
      }
    }

    .menu-is-open & {
      opacity: 1;
    }

    &Link {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      display: inline-block;
      padding: 0;
      text-decoration: none;
      vertical-align: top;
    }
  }

  &__menu {
    width: 100%;
    @include breakpoint(sm) {
      padding: 11px 14px;
    }

    .header__middle & {
      @include breakpoint(sm) {
        display: none;
      }
    }

    &Item {
      margin-bottom: 19px;
      height: 19px;
    }

    &Link {
      color: gray;
      display: block;
      font-family: $default;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      padding-left: 5px;
      position: relative;
      text-decoration: none;
      transition: all 0.3s ease;

      .dark-theme & {
        color: gray;
      }

      &.current {
        color: $headerColorWhite !important;

        &::before {
          background-color: $headerColorWhite;
        }
      }
    }
  }

  &__bottom {
    display: none;
    @include breakpoint(sm) {
      display: none;
    }
  }
}
