.wikiListMenu {
  background-color: $white;
  padding: 9px 0 0;
  transition: background-color 0.5s ease;
  @include breakpoint(sm) {
    padding: 5px 0 0;
  }

  .dark-theme & {
    background-color: $dark-2;
  }

  &__wrap {
    display: flex;
    margin: 0 -10px 14px;
    overflow: auto;
    position: relative;
    white-space: nowrap;
    @include breakpoint(sm) {
      justify-content: center;
      margin: 0 -11px 14px;
    }
  }

  &__list {
    border-bottom: 2px solid $light;
    display: block;
    padding: 0 10px !important;
    position: relative;
    transition: all 0.5s ease;
    white-space: nowrap;

    .dark-theme & {
      border-color: $dark-2;
    }
  }

  &__item {
    display: inline-block;
    padding: 0 9px;
    vertical-align: top;
    @include breakpoint(sm) {
      padding: 0 21px;
    }
  }

  &__link {
    background-color: transparent;
    border: 0;
    color: $gray;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 18px 0 16px;
    position: relative;
    text-decoration: none;
    transition: color 0.5s ease;
    vertical-align: top;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 19px;
      padding: 29px 0;
    }

    .dark-theme & {
      color: #778fcc;
    }

    &::before {
      background-color: transparent;
      bottom: -2px;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transition: all 0.5s ease;
      width: 100%;
    }

    &.active {
      color: $titleColor;

      &::before {
        background-color: $titleColor;
      }

      .dark-theme & {
        color: $white;

        &::before {
          background-color: $white;
        }
      }
    }

    &Text {
      &--full {
        display: none;
        @include breakpoint(sm) {
          display: block;
        }
      }

      &--short {
        @include breakpoint(sm) {
          display: none;
        }
      }
    }
  }
}
