.accesses {
  padding: 11px 10px;
  @include breakpoint(sm) {
    padding: 26px 0;
  }

  &__title.title {
    @include breakpoint(sm) {
      margin-bottom: 9px;
    }
  }

  &__container {
    padding-top: 5px;
  }

  &__list {
    background-color: $white;
    border-bottom: 1px solid $light-2;
    border-top: 1px solid $light-2;
    border-radius: 10px;
    //margin: 0 -20px;
    margin-right: -10px;
    overflow: auto;
    @include breakpoint(sm) {
      //margin: 0 -40px;
    }
    @include breakpoint(md) {
      border: 1px solid $light-2;
      border-radius: 10px;
      margin: 0 2px;
      //overflow: visible;

      .dark-theme & {
        border: 1px solid $dark-2;
      }
    }
    @include breakpoint(lg) {
      margin: 0;
    }

    .dark-theme & {
      background-color: $dark-2;
      border-bottom: 1px solid $dark-2;
      border-top: 1px solid $dark-2;
    }

    &Wrap {
      width: fit-content;
      padding: 23px 20px;
      @include breakpoint(lg) {
        padding: 23px 33px 23px 20px;
      }
    }
  }

  &__item {
    box-shadow: none;
    display: flex;
    transition: all 0.5s ease;
    width: fit-content;
    padding-right: 5px;
    border-bottom: 1px solid #dbe9f7;
    @include breakpoint(md) {
      &:not(.accesses__item--head):hover {
        border-radius: 15px;
        box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
        position: relative;
        z-index: 1;

        & + .accesses__item .accesses__itemCol::after,
        .accesses__itemCol::after {
          display: none;
        }

        .accesses__itemName {
          color: $grayHover;
        }
      }

      .dark-theme &:not(.accesses__item--head):hover {
        box-shadow: 5px 5px 10px $darkMedium;

        .accesses__itemCol,
        .accesses__itemDate,
        .accesses__itemName {
          color: $darkgrayHover;
        }

        .accesses__itemSite {
          &:hover {
            color: $white;
          }
        }
      }
    }

    .dark-theme & {
      border-bottom: 1px solid #222226;
    }

    &Inner {
      word-break: break-word;
      width: 100%;
    }

    &Name {
      color: $blue;
      font-weight: 600;
      max-width: 100%;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: all 0.5s ease;
      vertical-align: top;
      &:hover {
        color: $darkgrayHover !important;
      }
      .dark-theme & {
        color: $darkgrayHover;
        &:hover {
          color: $white !important;
        }
      }
    }

    &Date {
      color: $blueGrayLight;
      font-size: 12px;
      transition: all 0.5s ease;
      width: 100%;

      .dark-theme & {
        color: #b6c2e3;
      }
    }

    &Site {
      color: $grayHover;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: all 0.5s ease;
      white-space: nowrap;

      .dark-theme & {
        color: $darkgrayHover;
      }
    }
  }
}
