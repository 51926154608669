.searchPage {
  @include breakpoint(sm) {
    padding-top: 17px;
  }

  &__container {
    margin-bottom: 72px;
    margin-top: 30px;
    @include breakpoint(sm) {
      margin-bottom: 30px;
    }

    &Result {
      border: 1px solid $blueLight;
      border-radius: 10px;
      transition: all 0.5s ease;

      .dark-theme & {
        background-color: transparent;
        border: 1px solid $dark-2;
      }
    }
  }

  &__title {
    padding: 0 10px;
    @include breakpoint(sm) {
      padding: 0;
    }
  }

  &__input {
    display: none !important;
  }

  &__wrapper {
    &:first-of-type .searchPage__row:first-of-type .searchPage__rowTitle::before {
      display: none;
    }
  }

  &__row {
    margin-bottom: 15px;
    padding: 9px;
    @include breakpoint(sm) {
      margin-bottom: 21px;
      padding: 11px 22px;

      &Title {
        span {
          padding: 0 14px 0 0;
        }

        &::before {
          left: -22px;
          right: -22px;
        }
      }
    }

    &Title {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: -19px 0 16px;
      position: relative;

      span {
        background-color: $light;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding: 0 10px 0 0;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap;
        z-index: 1;

        .dark-theme & {
          background-color: $dark;
        }
      }

      &::before {
        background-color: $blueLight;
        content: "";
        height: 1px;
        left: -9px;
        position: absolute;
        right: -9px;
        top: 50%;

        .dark-theme & {
          background-color: $dark-2;
        }
      }
    }
  }

  &__item {
    margin-bottom: 8px;
    @include breakpoint(sm) {
      &Link {
        font-size: 16px;
        line-height: 24px;
        padding: 18px 20px;
      }
    }

    &Link {
      background-color: $white;
      border: 1px solid $blueLight;
      border-radius: 10px;
      color: $blue;
      display: block;
      font-size: 14px;
      line-height: 21px;
      padding: 18px 20px 21px;
      text-decoration: none;
      transition: all 0.5s ease;

      &Marked {
        background-color: #e5f1fd;
        color: $blue;

        .dark-theme & {
          background-color: $blue;
          color: $white;
        }
      }

      .dark-theme & {
        background-color: $dark-2;
        border: 1px solid $dark-2;
        color: #a0aac6;
      }

      .bx-no-touch &:hover {
        border: 1px solid transparent;
        box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
      }

      .bx-no-touch .dark-theme &:hover {
        box-shadow: 5px 5px 10px $darkMedium;
      }
    }
  }

  &__empty {
    padding: 17px 9px;
    @include breakpoint(sm) {
      padding: 17px 0;
    }

    &Title {
      color: $blueGrayLight;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 7px;
    }

    &Text {
      color: $blueGrayLight;
      font-size: 14px;
      line-height: 21px;

      a {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}
