// Пишем позорные решения или быстрые правки, например стилизация панели битрикс, где все сделано через id
// Если проблему можно решить, то необходимо это убрать из файла и написать как положено
.scroll .ps__thumb-y {
  background-color: $blue;
  width: 4px;
}

.scroll .ps__rail-y {
  width: 6px;
}

.scroll .ps__rail-y:hover .ps__thumb-y {
  right: 1px;
  background-color: $blue;
  width: 8px;
}

.scroll .ps__rail-y:hover > .ps__thumb-y,
.scroll .ps__rail-y:focus > .ps__thumb-y,
.scroll .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: $blue;
  width: 8px;
}

input[type="text"],
input[type="number"],
textarea {
  font-size: 16px;
}

.df-wrapper {
  display: flex;
  align-items: center;
}

.scrollElement {
  &::-webkit-scrollbar-thumb {
    background-color: #3262e0;
    border-radius: 20px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    color: $white;
  }

  &::-webkit-scrollbar-track {
    background-color: $grayDark;
  }
}
