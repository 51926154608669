.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    left: -9999px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $white;
    width: 18px;
    height: 18px;
    border: 1px solid $blueLight;
    border-radius: 4px;
    margin-right: 10px;
    transition: all 0.4s ease;
    background-position: center center;
  }

  ins {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-decoration: none;
    color: $defaultColor;
  }

  input:checked + span {
    background-color: $blue;
    border-color: $blue;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
  }

  .dark-theme & {
    ins {
      color: $white;
    }

    input:checked + span {
      background-color: $darkgrayHover;
      border-color: $darkgrayHover;
    }
  }
}
