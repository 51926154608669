.articles {
  padding: 0 9px;
  @include breakpoint(sm) {
    padding: 0;
  }

  h2 {
    margin-top: 27px;
    @include breakpoint(sm) {
      margin-top: 48px;
    }
  }

  &__block {
    margin-left: 5px;
    margin-right: 2px;
    padding-bottom: 20px;
    @include breakpoint(sm) {
      margin-right: 0;
    }
    @include breakpoint(md) {
      padding-bottom: 31px;
    }
    @include breakpoint(lg) {
      padding-bottom: 52px;
    }
  }

  &__catalog {
    border-left: 1px solid $blueLight;
    margin-bottom: 11px;
    padding-bottom: 1px;
    padding-left: 15px;
    position: relative;
    transition: all 0.5s ease;
    @include breakpoint(sm) {
      column-count: 2;
      column-gap: 10px;
      margin-bottom: 31px;
      padding-left: 34px;

      &::after {
        padding-bottom: 2px;
      }
    }
    @include breakpoint(md) {
      margin-bottom: 29px;
    }
    @include breakpoint(lg) {
      &::after {
        padding-bottom: 0;
      }
    }

    .dark-theme & {
      border-left: 1px solid $dark-2;
    }

    &::before,
    &::after {
      background-color: $light;
      color: $gray;
      display: flex;
      font-family: $default;
      font-size: 12px;
      font-weight: 500;
      left: 0;
      line-height: 16px;
      margin-left: -5px;
      position: absolute;
      transition: all 0.5s ease;
      width: 11px;
      z-index: 1;

      .dark-theme & {
        background-color: $dark;
      }
    }

    &::before {
      align-items: flex-start;
      height: 30px;
      padding-top: 1px;
      top: 0;
    }

    &::after {
      align-items: flex-end;
      bottom: 0;
      height: 34px;
      padding-bottom: 7px;
    }

    &--eng {
      &::before {
        content: "A";
      }

      &::after {
        content: "Z";
      }
    }

    &--rus {
      &::before {
        content: "А";
      }

      &::after {
        content: "Я";
      }
    }
  }

  &__elem {
    background-color: $white;
    border: 1px solid $light-2;
    border-radius: 10px;
    break-inside: avoid;
    margin-bottom: 10px;
    page-break-inside: avoid;
    transition: box-shadow 0.5s ease;
    will-change: transform;
    @include breakpoint(sm) {
      padding: 30px 0;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    @include breakpoint(md) {
      padding: 30px 0 27px;

      &:hover {
        box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
      }

      .dark-theme &:hover {
        box-shadow: 5px 5px 10px $darkMedium;
      }
    }
    @include breakpoint(lg) {
      border-radius: 15px;
    }

    .dark-theme & {
      background-color: $dark-2;
      border: 1px solid $dark-2;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__caption {
    align-items: center;
    color: $titleColor;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    line-height: 24px;
    padding-left: 22px;
    padding-right: 35px;
    position: relative;
    transition: all 0.5s ease;
    @include breakpoint(sm) {
      cursor: default;
      font-size: 18px;
      height: auto;
      line-height: 24px;
      margin-bottom: 11px;
      padding-left: 34px;

      &::after {
        display: none;
      }
    }
    @include breakpoint(md) {
      font-size: 22px;
    }

    .dark-theme & {
      color: $white;
    }

    &::after {
      color: $blue;
      content: "...";
      display: block;
      font-size: 21px;
      height: 24px;
      margin-top: -19px;
      position: absolute;
      right: 0;
      top: 50%;
      transition: all 0.5s ease;
      width: 31px;
      @include breakpoint(sm) {
        display: none;
      }

      .is-open & {
        color: $grayDark;
      }

      .dark-theme & {
        color: $white;
      }

      .dark-theme .is-open & {
        color: $grayDark;
      }
    }
  }

  &__list {
    display: none;
    overflow: hidden;
    transition: height 0.3s ease;
    @include breakpoint(sm) {
      display: block !important;
      height: auto !important;
    }

    &Wrap {
      padding: 1px 0;
    }

    &Item {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 8px;
      padding: 0 22px;

      @include breakpoint(sm) {
        margin-bottom: 8px;
        padding: 0 32px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include breakpoint(md) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    &Link {
      color: $blue;
      text-decoration: none;
      transition: all 0.5s ease;
      @include breakpoint(md) {
        &:hover {
          color: $dark;
        }

        .dark-theme &:hover {
          color: $white;
        }
      }

      .dark-theme & {
        color: $darkgrayHover;
      }
    }
  }
}

.show-list {
  display: block;
}
/* This fires as soon as the this.state.showList is false */
.articles__catalog-exit .articles__elem {
  opacity: 1;
}
/* fires as element leaves the DOM*/
.articles__catalog-exit-active .articles__elem {
  opacity: 0;
  transition: all 350ms;
}
