.tasksMenu {
  background-color: $white;
  overflow-x: auto;

  .dark-theme & {
    background-color: $dark-2;
  }

  &__wrap {
    height: 92px;
    margin: 0 -10px;
    overflow: hidden;
    @include breakpoint(sm) {
      height: 114px;
      margin: 0 -40px;
    }
    @include breakpoint(md) {
      height: 113px;
    }

    & > div {
      overflow-x: auto;
    }
  }

  &__list {
    padding-top: 14px;
    white-space: nowrap;
    @include breakpoint(sm) {
      padding-top: 19px;
    }
    @include breakpoint(md) {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
    }
    @include breakpoint(xl) {
      br {
        display: none;

        &.visible {
          display: block;
        }
      }
    }
  }

  &__item {
    align-items: center;
    display: inline-flex;
    margin-right: 9px;
    padding: 0 15px;
    @include breakpoint(sm) {
      margin-right: 0;
      padding: 0 10px 0 40px;

      &:last-of-type {
        padding: 0 40px;
      }
    }
    @include breakpoint(md) {
      flex-shrink: 0;
    }

    &Link {
      align-items: center;
      display: flex;
      height: 77px;
      position: relative;
      text-decoration: none;
      white-space: normal;
      @include breakpoint(sm) {
        height: 94px;
      }

      .tasksMenu__item--active &::after {
        background-color: $blue;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .dark-theme .tasksMenu__item--active &::after {
        background-color: $white;
      }

      &.active {
        &::after {
          background-color: $blue;
          bottom: 0;
          content: "";
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        .dark-theme & {
          &::after {
            background-color: $white;
          }
        }
      }
    }

    &Img {
      flex-shrink: 0;
      margin-right: 14px;
      @include breakpoint(sm) {
        margin-right: 20px;
      }

      svg {
        fill: $grayDark;

        .stwhite {
          fill: $white;

          .dark-theme & {
            fill: $dark-2;
          }
        }

        .tasksMenu__item--active & .st0,
        .active & .st0 {
          fill: $blue;
        }

        .dark-theme & {
          fill: $darkgrayHover;
        }

        .dark-theme .tasksMenu__item--active & .st0,
        .dark-theme .active & .st0 {
          fill: $blue;
        }
      }
    }

    &Text {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 7px;
      @include breakpoint(sm) {
        font-size: 14px;
        line-height: 19px;
      }

      .tasksMenu__item--active &,
      .active & {
        color: $titleColor;
      }

      .dark-theme &,
      .active & {
        color: #778fcc;
      }

      .dark-theme .tasksMenu__item--active &,
      .dark-theme .active & {
        color: $white;
      }
    }
  }

  .ps__rail-x {
    display: none !important;
  }
}
