.auth {
  height: 100%;
}

.login {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 31px 20px 104px;
  width: 100%;
  @include breakpoint(sm) {
    padding: 31px 83px;
  }
  @include breakpoint(md) {
    padding: 92px 40px;
  }

  &__wrap {
    background: $white;
    border: 1px solid transparent;
    border-radius: 15px;
    box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
    padding: 5px 20px 44px;
    position: relative;
    text-align: center;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 1;
    @include breakpoint(sm) {
      padding: 30px 110px 43px;
    }
    @include breakpoint(md) {
      align-items: center;
      display: flex;
      max-width: 945px;
      padding: 30px 8px;
    }
    @include breakpoint(xl) {
      padding: 30px 8px 47px;
    }

    .dark-theme & {
      background: linear-gradient(90deg, $dark-2, $dark-2);
      box-shadow: 5px 5px 10px $darkMedium;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    height: 245px;
    justify-content: center;
    width: 100%;
    @include breakpoint(sm) {
      height: 331px;

      svg {
        max-height: 242px;
        max-width: 250px;
      }
    }
    @include breakpoint(md) {
      height: 308px;
      width: 50%;
    }
    @include breakpoint(xl) {
      height: 320px;
    }

    svg {
      max-height: 191px;
      max-width: 198px;
    }
  }

  &__greeting {
    @include breakpoint(md) {
      padding-right: 70px;
      width: 50%;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;
    @include breakpoint(sm) {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 21px;
    }
    @include breakpoint(md) {
      margin-bottom: 15px;
    }
  }

  &__text {
    color: $gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 28px;
    @include breakpoint(sm) {
      margin-bottom: 39px;
    }
    @include breakpoint(md) {
      margin-bottom: 45px;
    }

    .dark-theme & {
      color: $headerColor;
    }
  }

  &__agreement {
    color: $headerColor;
    font-size: 12px;
    font-weight: 500;
    left: 0;
    line-height: 16px;
    margin-top: 33px;
    padding: 0 20px;
    position: absolute;
    top: 100%;
    width: 100%;
    @include breakpoint(sm) {
      margin: 21px auto 0;
      max-width: 285px;
      position: static;
    }
    @include breakpoint(md) {
      max-width: 300px;
    }

    .dark-theme & {
      color: #656565;
    }
  }
}
