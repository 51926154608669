.ui-table {
  background-color: $white;
  border-radius: 15px;
  color: $defaultColor;
  font-weight: 500;

  .dark-theme & {
    background-color: $dark-2;
    border-color: $dark;
    color: $blueGrayLight;
  }

  th {
    font-weight: normal;
    padding: 12px;
    text-align: center;
    white-space: nowrap;

    .dark-theme & {
      border-color: $dark;
      color: $white;
    }
  }

  .ui-table-underline-blue {
    font-weight: 500;
    position: relative;

    .dark-theme {
      border-color: $darkgrayHover;
    }
  }

  .ui-table-underline-blue::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid $blue;
  }

  .ui-table-underline-blue:last-child::after {
    width: 90%;
    right: 10%;
  }

  td {
    padding: 12px;
    text-align: center;
    transition: none;
    position: relative;
    white-space: nowrap;

    .dark-theme & {
      border-color: $dark;
    }
  }

  td::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $blueLight;
  }

  td:first-child::after {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    right: 0;
    border-top: 1px solid $blueLight;
    @include breakpoint(sm) {
      left: 8%;
    }
  }

  td:first-child {
    position: relative;
    padding-left: 25px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    transition: background-color 0.5s ease;

    &.checkTD {
      &::before {
        content: "";
        position: absolute;
        background: #d0ffbf;
        border-radius: 0 5px 5px 0;
        display: block;
        height: 40px;
        left: 0;
        margin-top: -10px;
        top: 36%;
        width: 4px !important;
        z-index: 1;
      }
    }
  }

  tr {
    box-shadow: none;
  }

  .bg-gray {
    background-color: $light;
    color: $blueGrayLight;

    .dark-theme & {
      border-color: $dark;
      background-color: $dark-4;
    }
  }

  tbody {
    tr {
      border-radius: 15px;
      transition: all 0.4s ease;
      @include hover {
        position: relative;
        box-shadow: 16px 10px 48px rgb(136 165 191 / 50%), -16px -2px 56px $white;
        z-index: 2;
        mix-blend-mode: darken;

        .dark-theme & {
          box-shadow: 5px 5px 10px $darkMedium;
          mix-blend-mode: unset;

          td:first-child {
            background-color: $darkgrayHover;
          }
        }

        td:first-child {
          background-color: $blue;
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
          color: $white;

          * {
            color: $white;
          }
        }

        td:last-child {
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          -moz-border-radius-bottomright: 15px;
          -moz-border-radius-topright: 15px;
        }

        td:first-child::after {
          width: 0;
        }

        + tr td:first-child::after {
          width: 0;
        }
      }

      td:last-child::after {
        right: 10%;
      }
    }
  }
}

.ui-table-round-left {
  border-top-left-radius: 8px;
}

.ui-table-round-right {
  border-top-right-radius: 8px;
}
