.footer {
  background-color: $white;
  flex-shrink: 0;
  text-align: center;
  transition: background-color 0.5s ease;

  .dark-theme & {
    background-color: $dark;
  }

  .wrapper {
    position: relative;
  }

  &__info {
    padding: 15px;
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 33px 20px 25px;
    }
    @include breakpoint(md) {
      padding: 26px 30px 25px;
    }
  }

  &__copyright {
    color: $headerColor;
    font-family: $secondary;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
