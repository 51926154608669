.selectItem {
  .select-dropdown {
    .dropdown-inner {
      overflow: hidden;
    }
  }
}

.error {
  color: $red;
}

.-invalid {
  input {
    border-color: $red;
  }
}

.whiteBack {
  background-color: $white;
  padding: 10px;
  transition: all 0.5s ease;

  @include breakpoint(sm) {
    padding: 15px 0 17px;
  }

  .dark-theme & {
    background-color: $dark;
  }
}

.d-sm-flex {
  @include breakpoint(sm) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.-valid {
  input {
    border-color: $green;
  }
}

.slick-slider {
  .slick-dots {
    bottom: 20px;
    left: 0;
    line-height: 9px;
    position: absolute;
    width: 100%;
    z-index: 1;

    li {
      display: inline-block;
      margin: 0 6px;
      padding: 0;
      transition: all 0.3s ease;
      vertical-align: top;
    }

    button {
      background-color: $grayDark;
      border: 0;
      border-radius: 50%;
      box-shadow: none;
      cursor: pointer;
      font-size: 0;
      height: 9px;
      padding: 0;
      transition: all 0.3s ease;
      width: 9px;
    }
  }
}

.slick-dots {
  .slick-active button {
    background-color: $blue;
  }
}

/* .ReactModal__Html--open,
.ReactModal__Body--open{
    overflow: hidden;
} */
.ReactModal__Body--open .ReactModalPortal {
  display: block;
}

.ReactModalPortal {
  display: none;
  z-index: 2000;
}

.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Content {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.ModalOverlay {
  background: rgba(255, 255, 255, 0.75);
  border: 0;
  cursor: default;
  height: 100%;
  left: 0;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 1500;

  .dark-theme & {
    background: rgba(34, 34, 38, 0.75);
  }
}

.ModalContent {
  background: $white;
  border-radius: 15px;
  box-shadow: 16px 10px 48px rgba(136, 165, 191, 0.5), -16px -2px 56px $white;
  max-width: 600px;
  min-width: 300px;
  padding: 57px 20px 60px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2000;

  .dark-theme & {
    background: #333335;
    box-shadow: 5px 5px 10px $darkMedium;
  }

  &__close {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 20px;

    svg {
      display: block;
      fill: #8592b6;

      .dark-theme & {
        fill: $white;
      }
    }
  }
}

.formBlock {
  &__content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  &__row {
    margin-bottom: 18px;
    padding: 0 5px 0 6px;
    width: 100%;

    @include breakpoint(sm) {
      width: 42%;

      &--textarea,
      &--file,
      &--actions {
        width: 84%;
      }
    }

    & .files {
      .dark-theme &,
      .dark-theme & a {
        color: $white;
      }

      ul {
        margin: 0;
      }

      li {
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--actions {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding-top: 25px;
      margin-top: 40px;

      @include breakpoint(sm) {
        justify-content: center;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
        background-color: $blueLight;
        border-radius: 7px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        margin-bottom: 10px;
        text-decoration: none;
        cursor: pointer;

        @include breakpoint(sm) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        button {
          margin-right: 8px;

          path {
            stroke: $blue;
          }

          &.deleteIcon,
          &.accessIcon {
            path {
              stroke: transparent;
              fill: $blue;
            }
          }
        }

        &-text {
          position: relative;

          &::after {
            content: attr(data-value);
            width: 100%;
            left: 0;
            font-size: 12px;
          }

          .dark-theme & {
            &::after {
              color: $white;
            }
          }
        }
      }
    }

    &--textarea {
      padding-top: 10px;
    }

    &--btn {
      margin-bottom: 0;
      padding-top: 6px;
    }
  }

  .styledSelect {
    width: 100%;

    &__control {
      border-radius: 7px;

      .dark-theme & {
        border: 1px solid $blueGray;

        &:hover {
          border: 1px solid $blueGray;
        }
      }
    }

    &__placeholder {
      color: $titleColor;
      font-size: 14px;
      line-height: 16px;
      margin: 0;

      .dark-theme & {
        color: #a0aac6;
      }
    }

    &__value-container {
      padding: 0 0 0 20px;
      text-align: left;
    }

    &__single-value {
      color: $titleColor;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

      .dark-theme & {
        color: $white;
      }
    }

    &__menu {
      color: $titleColor;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin: -4px 0 0;
      text-align: left;
    }

    &__option {
      color: $titleColor;
      padding: 18px 19px 16px;

      .dark-theme & {
        color: $white;
      }
    }
  }
}

.inputHolder {
  position: relative;

  &__input {
    background-color: transparent;
    border: 1px solid $blueLight;
    border-radius: 7px;
    color: $titleColor;
    font-family: $secondary;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 16px;
    padding: 18px 19px 16px;
    transition: all 0.3s ease;
    width: 100%;

    .dark-theme & {
      background-color: $dark;
      border: 1px solid $blueGray;
      color: $white;

      &:focus {
        border: 1px solid $darkgrayHover;
      }
    }

    &::placeholder {
      color: $titleColor;
      opacity: 1;
      transition: all 0.3s ease;

      .dark-theme & {
        color: #a0aac6;
      }
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &--textarea {
      height: 90px;
      resize: none;
    }
  }

  &__placeholder {
    position: absolute;
    z-index: 1;
    left: 19px;
    top: 18px;
    line-height: 18px;
    color: #3262e0;
    font-size: 16px;
    padding: 0 6px;
    background-color: $white;
    transition: all 0.4s ease;
    pointer-events: none;

    .-activePlaceholder & {
      z-index: 1;
      transform: translate(-18%, -155%) scale(0.7);
    }

    .dark-theme & {
      background-color: $dark;
      color: $white;
    }
  }
}

.addNewAccess__btn {
  align-items: center;
  background-color: $white;
  border: 1px solid $light-2;
  border-radius: 30px;
  box-shadow: none;
  color: $titleColor;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 0 auto;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 165px;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  .dark-theme & {
    background-color: $dark-2;
    border: 1px solid $blueGray;
    color: $white;
  }

  span {
    align-items: center;
    background-color: $blue;
    border-radius: 50%;
    display: inline-flex;
    height: 60px;
    justify-content: center;
    margin-left: -1px;
    margin-right: 18px;
    margin-top: -1px;
    transition: all 0.5s ease;
    width: 60px;

    .dark-theme & {
      background-color: $darkgrayHover;
    }

    .bx-no-touch .accessDetail__addLink:hover & {
      background-color: $darkgrayHover;
    }

    .bx-no-touch .dark-theme .accessDetail__addLink:hover {
      background-color: $blue;
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.formBlock .styledSelect__control .dark-theme--is-focused {
  border: 1px solid $darkgrayHover;

  &:hover {
    border: 1px solid $darkgrayHover;
  }
}

.bg-gray {
  background-color: $light;
  color: $blueGrayLight;

  .dark-theme & {
    background-color: $dark-4;
  }
}

.overflow-auto-xs {
  overflow: auto;

  @include breakpoint(md) {
    overflow: unset;
  }
}

.file-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  border: 1px dashed $blueLight;
  border-radius: 7px;
  min-height: 140px;
  transition: all 0.5s ease;

  &:hover {
    border-color: $blue;
  }

  &--info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $defaultColor;
    text-align: start;
    padding-left: 20px;

    .dark-theme & {
      color: $white;
    }
  }

  &--limits {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $grayDark;
    text-align: start;
    padding-left: 20px;
  }

  &-files {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    &__item {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: $blue;

      & .uploaded {
        color: $green;
        cursor: pointer;
      }

      & .deleting {
        color: $red;
        cursor: pointer;
      }

      span {
        display: flex;
        flex-shrink: 0;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $red;
    text-align: start;
  }
}

.tooltip {
  position: relative;

  &__text {
    display: none;
    position: absolute;
    z-index: -1;
    white-space: nowrap;
    opacity: 0;
    align-items: center;
    justify-content: center;
    padding: 13px 22px;
    font-size: 11px;
    background: #dde6ff;
    border-radius: 5px;
    transition: all 0.5s ease;
    pointer-events: none;
    left: -115px;
    top: -60px;
    right: 0;

    @include breakpoint(md) {
      left: -58px;
      right: unset;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      right: 10px;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left-width: 5px;
      border-right-width: 5px;
      border-top: 5px solid #dde6ff;

      @include breakpoint(md) {
        right: 47%;
      }
    }
  }

  &:hover {
    .tooltip__text {
      display: flex;
      opacity: 1;
      z-index: 2;
    }
  }
}
