.error-page {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #5a6788;
}

.error-text {
  font-family: 'Roboto Light', serif;
  font-weight: 300;
  font-size: 40px;
  margin-top: 30%;
  color: #dbd9d9;
}
