.containerUp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 10%;
  right: 5%;
  cursor: pointer;
  background-color: #5a6788;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transition: opacity 0.2s ease-in;
  box-shadow: 0 0 30px 9px rgba(93, 148, 191, 0.2);
  z-index: 9;
}
.containerUp:hover {
  opacity: 1;
}
.containerDown {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  top: 10%;
  left: 5%;
  cursor: pointer;
  background-color: #5a6788;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
}

.hide {
  display: none;
}
.arrowUp {
  width: 35px;
  height: 35px;
  transform: rotateZ(270deg);
}
.arrowDown {
  width: 35px;
  height: 35px;
  transform: rotateZ(90deg);
}
