/* https://medium.com/@stasonmars/%D1%81%D0%BE%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D1%8B%D0%B8%CC%86-%D1%81%D0%B1%D1%80%D0%BE%D1%81-css-f5816963c82b */
/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  line-height: 1.5;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  display: block;
  max-width: 100%;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

* {
  box-sizing: border-box;
  outline: none;

  &:focus {
    outline: none;
  }
}

a {
  &:focus {
    outline: none;
  }
}

input::-ms-clear {
  display: none;
}

html {
  height: 100%;
  min-width: 320px;
}

body::-webkit-scrollbar {
  width: 5px;
  color: #fff;
}

body::-webkit-scrollbar-track {
  background-color: #283149;
}

body::-webkit-scrollbar-thumb {
  background-color: #3262e0;
  border-radius: 20px;
}

body {
  scrollbar-color: #d4d4de #e0e0e0;
  background-color: $light;
  display: flex;
  flex-direction: column;
  font-family: $default;
  font-size: $defaultSize;
  font-weight: normal;
  height: 100%;
  line-height: 24px;
  margin: 0;
  min-width: 320px;
  text-size-adjust: 100%;
  transition: background-color 0.5s ease;

  &.dark-theme {
    background-color: $dark;
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

main {
  display: flex;
  width: 100%;

  &.main-page {
    height: calc(100% - 64px);
    @include breakpoint(sm) {
      height: calc(100% - 107px);
    }
  }
}

.content {
  width: 100%;
  flex: 1 0 auto;
}

.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 10px;
  width: 100%;
  @include breakpoint(sm) {
    padding: 0 40px;
  }
}

.overflow {
  overflow: hidden;
}

html.overflow body {
  overflow: hidden;
}

html.menu-is-open {
  overflow: hidden;
  @include breakpoint(sm) {
    overflow: visible;
  }
}

html.menu-is-open body {
  overflow: hidden;
  @include breakpoint(sm) {
    overflow: visible;
  }
}

.hidden-block {
  display: none !important;
}
